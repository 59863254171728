import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  PageHeader,
  Row,
  Table,
  Tooltip,
} from "antd";

import { Content } from "antd/lib/layout/layout";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { VatPayBalance, getUnPaidVAT } from "../../../actions/account";
import {
  renderDate,
  renderInput,
  renderSelect,
} from "../../../components/form";
import SelectBox from "../../../components/select";
const { RangePicker } = DatePicker;
const tailLayout = {
  wrapperCol: { offset: 12, span: 2 },
};
const UnPaidVat = ({ getUnPaidVAT, VatPayBalance }) => {
  const [data, setData] = useState([]);
  const [totalvat, setTotalVAt] = useState([null]);
  const [form] = Form.useForm();
  const [ds, setDs] = useState([null]);
  const [de, setDe] = useState([null]);
  const [PayVatVisibale, setPayVatVisibale] = useState(false);
  const [bpo, setBPO] = useState("");
  const [pt, setPaymentType] = useState("");
  const [docimg, setDocImg] = useState(false);

  const banks = [
    { value: "AB Bank LTD", label: "AB Bank LTD" },
    { value: "Agrani Bank LTD", label: "Agrani Bank LTD" },
    { value: "Al-Arafah Islami Bank LTD", label: "Al-Arafah Islami Bank LTD" },
    { value: "BASIC Bank LTD", label: "BASIC Bank LTD" },
    {
      value: "BDBL (Bangladesh Development Bank LTD)",
      label: "BDBL (Bangladesh Development Bank LTD)",
    },
    { value: "BRAC Bank LTD", label: "BRAC Bank LTD" },
    {
      value: "Bangladesh Commerce Bank LTD",
      label: "Bangladesh Commerce Bank LTD",
    },
    { value: "Bank Al-Falah", label: "Bank Al-Falah" },
    { value: "Bank Asia LTD", label: "Bank Asia LTD" },
    { value: "Citibank NA", label: "Citibank NA" },
    { value: "Commercial Bank of Ceylon", label: "Commercial Bank of Ceylon" },
    { value: "Dhaka Bank LTD", label: "Dhaka Bank LTD" },
    { value: "Dutch Bangla Bank LTD", label: "Dutch Bangla Bank LTD" },
    { value: "Eastern Bank LTD", label: "Eastern Bank LTD" },
    {
      value: "Export Import Bank of Bangladesh LTD",
      label: "Export Import Bank of Bangladesh LTD",
    },
    {
      value: "First Security Islami Bank LTD",
      label: "First Security Islami Bank LTD",
    },
    { value: "HSBC", label: "HSBC" },
    { value: "Habib Bank LTD", label: "Habib Bank LTD" },
    { value: "ICB Islamic Bank LTD", label: "ICB Islamic Bank LTD" },
    { value: "IFIC Bank LTD", label: "IFIC Bank LTD" },
    {
      value: "Islami Bank Bangladesh LTD",
      label: "Islami Bank Bangladesh LTD",
    },
    { value: "Jamuna Bank LTD", label: "Jamuna Bank LTD" },
    { value: "Janata Bank LTD", label: "Janata Bank LTD" },
    { value: "Meghna Bank LTD", label: "Meghna Bank LTD" },
    { value: "Mercantile Bank LTD", label: "Mercantile Bank LTD" },
    { value: "Midland Bank LTD", label: "Midland Bank LTD" },
    { value: "Modhumoti Bank LTD", label: "Modhumoti Bank LTD" },
    { value: "Mutual Trust Bank LTD", label: "Mutual Trust Bank LTD" },
    { value: "NCC Bank LTD", label: "NCC Bank LTD" },
    { value: "NRB Bank LTD", label: "NRB Bank LTD" },
    { value: "NRB Commercial Bank LTD", label: "NRB Commercial Bank LTD" },
    { value: "NRB Global Bank LTD", label: "NRB Global Bank LTD" },
    { value: "National Bank LTD", label: "National Bank LTD" },
    { value: "National Bank of Pakistan", label: "National Bank of Pakistan" },
    { value: "One Bank LTD", label: "One Bank LTD" },
    { value: "Prime Bank LTD", label: "Prime Bank LTD" },
    { value: "Pubali Bank LTD", label: "Pubali Bank LTD" },
    { value: "Rupali Bank LTD", label: "Rupali Bank LTD" },
    { value: "Shahjalal islami Bank LTD", label: "Shahjalal islami Bank LTD" },
    { value: "Simanto Bank LTD", label: "Simanto Bank LTD" },
    { value: "Social Islami Bank LTD", label: "Social Islami Bank LTD" },
    { value: "Sonali Bank LTD", label: "Sonali Bank LTD" },
    {
      value: "South Bangla Agriculture and Commerce Bank LTD",
      label: "South Bangla Agriculture and Commerce Bank LTD",
    },
    { value: "Southeast Bank LTD", label: "Southeast Bank LTD" },
    { value: "Standard Bank LTD", label: "Standard Bank LTD" },
    { value: "Standard Chartered Bank", label: "Standard Chartered Bank" },
    { value: "State Bank of India", label: "State Bank of India" },
    { value: "The City Bank LTD", label: "The City Bank LTD" },
    { value: "The Farmers Bank LTD", label: "The Farmers Bank LTD" },
    { value: "The Premier Bank LTD", label: "The Premier Bank LTD" },
    { value: "Trust Bank LTD", label: "Trust Bank LTD" },
    { value: "Union Bank LTD", label: "Union Bank LTD" },
    {
      value: "United Commercial Bank LTD",
      label: "United Commercial Bank LTD",
    },
    { value: "Uttara Bank LTD", label: "Uttara Bank LTD" },
    { value: "Woori Bank", label: "Woori Bank" },
  ];
  const account_type = [
    { value: "Saving", label: "Saving" },
    { value: "Current", label: "Current" },
  ];
  const payment_method = [
    { value: "Mobile Banking", label: "Mobile Banking" },
    { value: "Bank Account", label: "Bank Account" },
    { value: "Cash", label: "Cash" },
  ];
  const mobile_type = [
    { value: "bKash", label: "bKash" },
    { value: "Rocket", label: "Rocket" },
    { value: "Nagad", label: "Nagad" },
  ];
  const bank_payment_option = [
    { value: "Check Voucher", label: "Check Voucher" },
    { value: "Voucher", label: "Voucher" },
  ];
  useEffect(async () => {
    window.scrollTo(0, 0);
  }, []);
  const handleDate = (date) => {
    if (date) {
      let ds = new Date(date[0]._d);
      let de = new Date(date[1]._d);
      setDs(ds.toISOString());
      setDe(de.toISOString());
    } else {
      setDs(null);
      setDe(null);
      return;
    }
  };
  const handleApiSearch = async () => {
    const res = await getUnPaidVAT(ds, de);

    setData(res);
    setTotalVAt(res.total_vat[0].vat);
  };
  const handlePageChange = async () => {
    const res = await getUnPaidVAT(ds, de);

    setData(res);
  };
  const onFinishVat = async (values) => {
    // console.log(values)
    const code = await VatPayBalance(values, docimg);

    if (code === 201) {
      form.resetFields();
      setPayVatVisibale(false);
    }
  };
  const handleOkVat = async () => {
    setPayVatVisibale(false);
  };

  const handleCancelVat = () => {
    setPayVatVisibale(false);
  };
  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };
  const searchColumns = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            autoFocus
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
            style={{ marginBottom: 8, display: "block" }}
          ></Input>
          <Button
            onClick={() => {
              confirm();
            }}
            type="primary"
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          &nbsp;
          <Button
            onClick={() => {
              clearFilters();
            }}
            size="small"
            type="danger"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      );
    },
    filterIcon: () => {
      return <SearchOutlined />;
    },
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
  });

  const columns = [
    {
      title: "Transaction ID",
      dataIndex: "transaction_id",
      key: "transaction_id",
      width: "25%",
      ...searchColumns("transaction_id"),
    },
    {
      title: "Delivery Charge",
      dataIndex: "delivery_charge",
      key: "delivery_charge",
      width: "20%",
      ...searchColumns("delivery_charge"),
    },
    {
      title: "COD ",
      dataIndex: "cod",
      key: "cod",
      width: "15%",
      ...searchColumns("cod"),
    },
    {
      title: "Has To PAY ",
      dataIndex: "has_to_pay",
      key: "has_to_pay",
      width: "15%",
      ...searchColumns("has_to_pay"),
    },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      width: "18%",
    },

    {
      title: "VAT",
      dataIndex: "vat",
      key: "vat",
      width: "15%",
      ...searchColumns("vat"),
    },
  ];
  const createData = (
    transaction_id,
    delivery_charge,
    cod,
    has_to_pay,
    created_at,
    vat
  ) => {
    return {
      transaction_id,
      delivery_charge,
      cod,
      has_to_pay,
      created_at,

      vat,
    };
  };

  const rows = [
    data
      ? data?.list?.map((TRANSACTION, id) =>
          createData(
            TRANSACTION?.transaction_id,
            TRANSACTION?.delivery_charge,
            TRANSACTION?.cod,

            TRANSACTION?.has_to_pay,

            moment.utc(TRANSACTION.created_at).format("YYYY-MM-DD hh:mm A"),
            TRANSACTION?.vat
          )
        )
      : "",
  ];

  const Pageheader = (
    <>
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="UnPaid Vat"
        subTitle="Finance Management"
      ></PageHeader>
    </>
  );
  const TABLE = (
    <>
      <br />
      <Row
        style={{
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <Col xs={12} sm={24} md={12} lg={12} xl={12}>
          <RangePicker
            onChange={(e) => {
              handleDate(e);
            }}
            format="YYYY/MM/DD"
            style={{ width: "80%" }}
          />
        </Col>
        <Tooltip title="search" onClick={handleApiSearch}>
          <Button type="primary" shape="circle" icon={<SearchOutlined />} />
        </Tooltip>
      </Row>
      <br></br>

      <Table
        columns={columns}
        dataSource={rows[0]}
        scroll={{ x: 1000 }}
        sticky
      />
    </>
  );
  const Payvat = (
    <>
      <Modal
        title="Pay Vat"
        centered
        visible={PayVatVisibale}
        cancelText="Close"
        okButtonProps={{ style: { display: "none" } }}
        onCancel={handleCancelVat}
        height={600}
      >
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 12 }}
          initialValues={{ remember: true }}
          onFinish={onFinishVat}
          onFinishFailed={onFinishFailed}
          style={{ alignSelf: "center" }}
          requiredMark={false}
          form={form}
        >
          <Divider style={{ color: "#820374" }}>Pay Vat</Divider>
          {renderDate("ds", "From", [
            { required: true, message: "Field is required" },
          ])}
          {renderDate("de", "To", [
            { required: true, message: "Field is required" },
          ])}
          {renderDate("date", "Collection Date", [
            { required: true, message: "Field is required" },
          ])}

          {renderInput("note", "Note", [
            { required: true, message: "Field is required" },
          ])}
          <SelectBox
            name="payment_method"
            label="Payment Method"
            rules={[{ required: true, message: "payment type is required" }]}
            options={payment_method}
            placeholder="Select One"
            onChange={(e) => {
              setPaymentType(e);
            }}
          />
          {pt === "Mobile Banking" ? (
            <>
              {renderSelect(
                "mobile_bank",
                "Mobile Banking*",
                [{ required: true }],
                "Select One",
                mobile_type,
                "none"
              )}
              {renderInput("mobile_banking_account", "Mobile Number ", [
                { required: true, message: "Field is required" },
              ])}
              {renderInput("tx_id", "Transaction ID", [
                { required: true, message: "Field is required" },
              ])}

              <br></br>
            </>
          ) : null}
          {pt === "Bank Account" ? (
            <>
              <SelectBox
                name="bank_payment_option"
                label="Bank payment option"
                rules={[{ required: true, message: "Field is required" }]}
                options={bank_payment_option}
                placeholder="Select One"
                onChange={(e) => {
                  setBPO(e);
                }}
              />

              {bpo === "Check Voucher" ? (
                <>
                  {renderSelect(
                    "bank_name",
                    "Bank Name*",
                    [{ required: true }],
                    "Select One",
                    banks,
                    "none"
                  )}

                  {renderInput("branch_name", "Brance Name", [
                    { required: true, message: "Field is required" },
                  ])}
                  {renderInput("check_holder", "Check holder", [
                    { required: true, message: "field is required" },
                  ])}
                  {renderInput("issue_name", "Issuer Name ", [
                    { required: true, message: "field is required" },
                  ])}
                  {renderInput("check_number", "Check Number", [
                    { required: true, message: "field is required" },
                  ])}
                </>
              ) : null}
              {bpo === "Voucher" ? (
                <>
                  {renderSelect(
                    "bank_name",
                    "Bank Name*",
                    [{ required: true }],
                    "Select One",
                    banks,
                    "none"
                  )}

                  {renderInput("branch_name", "Brance Name", [
                    { required: true, message: "Field is required" },
                  ])}
                </>
              ) : null}
            </>
          ) : null}
          <Form.Item label="Image of Documnet">
            <input
              type="file"
              id="avatar"
              name="doc_img"
              accept="image/png, image/jpeg"
              onChange={(e) => {
                setDocImg(e.target.files[0]);
              }}
            ></input>
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
  return (
    <Content>
      {Pageheader}
      <Divider>
        Total unpaid VAT:{totalvat} TK{" "}
        <Button
          hoverable={true}
          style={{ backgroundColor: "#820374", color: "white" }}
          onClick={() => {
            setPayVatVisibale(true);
          }}
        >
          PAY
        </Button>
      </Divider>
      {TABLE}
      {Payvat}
    </Content>
  );
};

export default connect(null, { getUnPaidVAT, VatPayBalance })(UnPaidVat);
