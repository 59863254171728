import { Button, Divider, Form, Modal, Select } from "antd";
import React, { useState } from "react";
import { connect } from "react-redux";
import { getRoles } from "../../../actions/role";
import {
  renderDate,
  renderInput,
  renderSelect,
} from "../../../components/form";
import SelectBox from "../../../components/select";
const { Option } = Select;

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 4, span: 16 },
};

const gender = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
  { value: "other", label: "Other" },
];
const status = [
  { value: true, label: "Active" },
  { value: false, label: "Inactive" },
];

const PayLoan = ({ title, visible, cancel, refresh, ok, api, ID }) => {
  const [pt, setPaymentType] = useState("");
  const [data, setData] = useState([]);

  const [docimg, setDocImg] = useState(false);
  const [StatusType, setStatusType] = useState(null);
  const [StateType, setStateType] = useState("");

  const [bpo, setBPO] = useState("");

  const [form] = Form.useForm();
  form.setFieldsValue({
    loan: ID,
  });
  const pickup_type = [
    { value: "Electronics", label: "Electronics" },
    { value: "Health & Beauty", label: "Health & Beauty" },
    { value: "Jewelry", label: "Jewelry" },
    { value: "Clothing", label: "Clothing" },
    { value: "Craft", label: "Craft" },
    { value: "Fashion Accessories", label: "Fashion Accessories" },
    { value: "Fragile", label: "Fragile" },
    { value: "Groceries", label: "Groceries" },
    { value: "Others", label: "Others" },
  ];

  const banks = [
    { value: "AB Bank LTD", label: "AB Bank LTD" },
    { value: "Agrani Bank LTD", label: "Agrani Bank LTD" },
    { value: "Al-Arafah Islami Bank LTD", label: "Al-Arafah Islami Bank LTD" },
    { value: "BASIC Bank LTD", label: "BASIC Bank LTD" },
    {
      value: "BDBL (Bangladesh Development Bank LTD)",
      label: "BDBL (Bangladesh Development Bank LTD)",
    },
    { value: "BRAC Bank LTD", label: "BRAC Bank LTD" },
    {
      value: "Bangladesh Commerce Bank LTD",
      label: "Bangladesh Commerce Bank LTD",
    },
    { value: "Bank Al-Falah", label: "Bank Al-Falah" },
    { value: "Bank Asia LTD", label: "Bank Asia LTD" },
    { value: "Citibank NA", label: "Citibank NA" },
    { value: "Commercial Bank of Ceylon", label: "Commercial Bank of Ceylon" },
    { value: "Dhaka Bank LTD", label: "Dhaka Bank LTD" },
    { value: "Dutch Bangla Bank LTD", label: "Dutch Bangla Bank LTD" },
    { value: "Eastern Bank LTD", label: "Eastern Bank LTD" },
    {
      value: "Export Import Bank of Bangladesh LTD",
      label: "Export Import Bank of Bangladesh LTD",
    },
    {
      value: "First Security Islami Bank LTD",
      label: "First Security Islami Bank LTD",
    },
    { value: "HSBC", label: "HSBC" },
    { value: "Habib Bank LTD", label: "Habib Bank LTD" },
    { value: "ICB Islamic Bank LTD", label: "ICB Islamic Bank LTD" },
    { value: "IFIC Bank LTD", label: "IFIC Bank LTD" },
    {
      value: "Islami Bank Bangladesh LTD",
      label: "Islami Bank Bangladesh LTD",
    },
    { value: "Jamuna Bank LTD", label: "Jamuna Bank LTD" },
    { value: "Janata Bank LTD", label: "Janata Bank LTD" },
    { value: "Meghna Bank LTD", label: "Meghna Bank LTD" },
    { value: "Mercantile Bank LTD", label: "Mercantile Bank LTD" },
    { value: "Midland Bank LTD", label: "Midland Bank LTD" },
    { value: "Modhumoti Bank LTD", label: "Modhumoti Bank LTD" },
    { value: "Mutual Trust Bank LTD", label: "Mutual Trust Bank LTD" },
    { value: "NCC Bank LTD", label: "NCC Bank LTD" },
    { value: "NRB Bank LTD", label: "NRB Bank LTD" },
    { value: "NRB Commercial Bank LTD", label: "NRB Commercial Bank LTD" },
    { value: "NRB Global Bank LTD", label: "NRB Global Bank LTD" },
    { value: "National Bank LTD", label: "National Bank LTD" },
    { value: "National Bank of Pakistan", label: "National Bank of Pakistan" },
    { value: "One Bank LTD", label: "One Bank LTD" },
    { value: "Prime Bank LTD", label: "Prime Bank LTD" },
    { value: "Pubali Bank LTD", label: "Pubali Bank LTD" },
    { value: "Rupali Bank LTD", label: "Rupali Bank LTD" },
    { value: "Shahjalal islami Bank LTD", label: "Shahjalal islami Bank LTD" },
    { value: "Simanto Bank LTD", label: "Simanto Bank LTD" },
    { value: "Social Islami Bank LTD", label: "Social Islami Bank LTD" },
    { value: "Sonali Bank LTD", label: "Sonali Bank LTD" },
    {
      value: "South Bangla Agriculture and Commerce Bank LTD",
      label: "South Bangla Agriculture and Commerce Bank LTD",
    },
    { value: "Southeast Bank LTD", label: "Southeast Bank LTD" },
    { value: "Standard Bank LTD", label: "Standard Bank LTD" },
    { value: "Standard Chartered Bank", label: "Standard Chartered Bank" },
    { value: "State Bank of India", label: "State Bank of India" },
    { value: "The City Bank LTD", label: "The City Bank LTD" },
    { value: "The Farmers Bank LTD", label: "The Farmers Bank LTD" },
    { value: "The Premier Bank LTD", label: "The Premier Bank LTD" },
    { value: "Trust Bank LTD", label: "Trust Bank LTD" },
    { value: "Union Bank LTD", label: "Union Bank LTD" },
    {
      value: "United Commercial Bank LTD",
      label: "United Commercial Bank LTD",
    },
    { value: "Uttara Bank LTD", label: "Uttara Bank LTD" },
    { value: "Woori Bank", label: "Woori Bank" },
  ];
  const account_type = [
    { value: "Saving", label: "Saving" },
    { value: "Current", label: "Current" },
  ];
  const payment_method = [
    { value: "Mobile Banking", label: "Mobile Banking" },
    { value: "Bank Account", label: "Bank Account" },
    { value: "Cash", label: "Cash" },
  ];
  const mobile_type = [
    { value: "bKash", label: "bKash" },
    { value: "Rocket", label: "Rocket" },
    { value: "Nagad", label: "Nagad" },
  ];
  const bank_payment_option = [
    { value: "Check Voucher", label: "Check Voucher" },
    { value: "Voucher", label: "Voucher" },
  ];

  const active = [
    { value: "true", label: "Yes" },
    { value: "false", label: "No" },
  ];
  const authenticated = [
    { value: "true", label: "Yes" },
    { value: "false", label: "No" },
  ];
  const merchant = [
    { value: "Company", label: "Company" },
    { value: "Individual", label: "Individual" },
  ];
  const doc = [
    { value: "NID", label: "NID" },
    { value: "Passport", label: "Passport" },
    { value: "Driving_Licence", label: "Driving Licence" },
    { value: "None", label: "None" },
  ];

  const onFinish = async (values) => {
    const code = await api(values, docimg);

    if (code === 201) {
      cancel();
      form.resetFields();
      refresh();
    }
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  const MODAL = (
    <Modal
      title={title}
      centered
      visible={visible}
      width={800}
      onCancel={cancel}
      cancelText="Close"
      okButtonProps={{ style: { display: "none" } }}
    >
      <>
        <Form
          {...layout}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={form}
        >
          <Divider style={{ color: "#820374" }}>
            <strong>Receiver Info</strong>
          </Divider>
          {/* {renderInput("loan", "Loan ID", [
          { required: true, message: "field is required" },
        ])} */}
          <Form.Item label="Loan ID" hidden={true} name="loan">
            {/* <Input size="large" type="hidden"  /> */}
          </Form.Item>

          {StatusType === "new" ? (
            <>
              {renderInput("new_status", "Add New", [
                { required: true, message: "field is required" },
              ])}
            </>
          ) : null}
          {renderDate("date", "Pay Date", [
            { required: true, message: "Field is required" },
          ])}
          {renderInput("amount", "pay Amount", [
            { required: true, message: "field is required" },
          ])}
          {renderInput("note", "Note", [
            { required: true, message: "Field is required" },
          ])}
          <SelectBox
            name="payment_method"
            label="Payment Method"
            rules={[{ required: true, message: "payment type is required" }]}
            options={payment_method}
            placeholder="Select One"
            onChange={(e) => {
              setPaymentType(e);
            }}
          />
          {pt === "Mobile Banking" ? (
            <>
              {renderSelect(
                "mobile_bank",
                "Mobile Banking Name*",
                [{ required: true }],
                "Select One",
                mobile_type,
                "none"
              )}
              {renderInput("mobile_banking_account", "Mobile Number ", [
                { required: true, message: "Field is required" },
              ])}
              {renderInput("tx_id", "Transaction ID", [
                { required: true, message: "Field is required" },
              ])}

              <br></br>
            </>
          ) : null}
          {pt === "Bank Account" ? (
            <>
              <SelectBox
                name="bank_payment_option"
                label="Bank payment option"
                rules={[{ required: true, message: "Field is required" }]}
                options={bank_payment_option}
                placeholder="Select One"
                onChange={(e) => {
                  setBPO(e);
                }}
              />

              {bpo === "Check Voucher" ? (
                <>
                  {renderSelect(
                    "bank_name",
                    "Bank Name*",
                    [{ required: true }],
                    "Select One",
                    banks,
                    "none"
                  )}

                  {renderInput("branch_name", "Brance Name", [
                    { required: true, message: "Field is required" },
                  ])}
                  {renderInput("check_holder", "Check holder", [
                    { required: true, message: "field is required" },
                  ])}
                  {renderInput("issue_name", "Issuer Name ", [
                    { required: true, message: "field is required" },
                  ])}
                  {renderInput("check_number", "Check Number", [
                    { required: true, message: "field is required" },
                  ])}
                </>
              ) : null}
              {bpo === "Voucher" ? (
                <>
                  {renderSelect(
                    "bank_name",
                    "Bank Name*",
                    [{ required: true }],
                    "Select One",
                    banks,
                    "none"
                  )}

                  {renderInput("branch_name", "Brance Name", [
                    { required: true, message: "Field is required" },
                  ])}
                </>
              ) : null}
            </>
          ) : null}
          <Form.Item label="Image of Documnet">
            <input
              type="file"
              id="avatar"
              name="doc_img"
              accept="image/png, image/jpeg"
              onChange={(e) => {
                setDocImg(e.target.files[0]);
              }}
            ></input>
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </>
    </Modal>
  );

  return <>{MODAL}</>;
};

export default connect(null, { getRoles })(PayLoan);
