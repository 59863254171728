import {
  Button,
  Card,
  Col,
  Divider,
  Empty,
  Form,
  Modal,
  PageHeader,
  Row,
  Select,
  Timeline,
} from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { Content } from "antd/lib/layout/layout";

import { EditOutlined, KeyOutlined } from "@ant-design/icons";

import {
  changeManagerPass,
  getManager,
  getManagerEditData,
  getWarehouses,
  updateWarehouseManager,
} from "../../../actions/warehouse";

import moment from "moment";
import { Title } from "../../../Style";
import {
  renderInput,
  renderPasswordField,
  renderTextField,
} from "../../../components/form";
const { Option } = Select;
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 12 },
};
const tailLayout = {
  wrapperCol: { offset: 6, span: 14 },
};

const banks = [
  { value: "AB Bank LTD", label: "AB Bank LTD" },
  { value: "Agrani Bank LTD", label: "Agrani Bank LTD" },
  { value: "Al-Arafah Islami Bank LTD", label: "Al-Arafah Islami Bank LTD" },
  { value: "BASIC Bank LTD", label: "BASIC Bank LTD" },
  {
    value: "BDBL (Bangladesh Development Bank LTD)",
    label: "BDBL (Bangladesh Development Bank LTD)",
  },
  { value: "BRAC Bank LTD", label: "BRAC Bank LTD" },
  {
    value: "Bangladesh Commerce Bank LTD",
    label: "Bangladesh Commerce Bank LTD",
  },
  { value: "Bank Al-Falah", label: "Bank Al-Falah" },
  { value: "Bank Asia LTD", label: "Bank Asia LTD" },
  { value: "Citibank NA", label: "Citibank NA" },
  { value: "Commercial Bank of Ceylon", label: "Commercial Bank of Ceylon" },
  { value: "Dhaka Bank LTD", label: "Dhaka Bank LTD" },
  { value: "Dutch Bangla Bank LTD", label: "Dutch Bangla Bank LTD" },
  { value: "Eastern Bank LTD", label: "Eastern Bank LTD" },
  {
    value: "Export Import Bank of Bangladesh LTD",
    label: "Export Import Bank of Bangladesh LTD",
  },
  {
    value: "First Security Islami Bank LTD",
    label: "First Security Islami Bank LTD",
  },
  { value: "HSBC", label: "HSBC" },
  { value: "Habib Bank LTD", label: "Habib Bank LTD" },
  { value: "ICB Islamic Bank LTD", label: "ICB Islamic Bank LTD" },
  { value: "IFIC Bank LTD", label: "IFIC Bank LTD" },
  {
    value: "Islami Bank Bangladesh LTD",
    label: "Islami Bank Bangladesh LTD",
  },
  { value: "Jamuna Bank LTD", label: "Jamuna Bank LTD" },
  { value: "Janata Bank LTD", label: "Janata Bank LTD" },
  { value: "Meghna Bank LTD", label: "Meghna Bank LTD" },
  { value: "Mercantile Bank LTD", label: "Mercantile Bank LTD" },
  { value: "Midland Bank LTD", label: "Midland Bank LTD" },
  { value: "Modhumoti Bank LTD", label: "Modhumoti Bank LTD" },
  { value: "Mutual Trust Bank LTD", label: "Mutual Trust Bank LTD" },
  { value: "NCC Bank LTD", label: "NCC Bank LTD" },
  { value: "NRB Bank LTD", label: "NRB Bank LTD" },
  { value: "NRB Commercial Bank LTD", label: "NRB Commercial Bank LTD" },
  { value: "NRB Global Bank LTD", label: "NRB Global Bank LTD" },
  { value: "National Bank LTD", label: "National Bank LTD" },
  { value: "National Bank of Pakistan", label: "National Bank of Pakistan" },
  { value: "One Bank LTD", label: "One Bank LTD" },
  { value: "Prime Bank LTD", label: "Prime Bank LTD" },
  { value: "Pubali Bank LTD", label: "Pubali Bank LTD" },
  { value: "Rupali Bank LTD", label: "Rupali Bank LTD" },
  { value: "Shahjalal islami Bank LTD", label: "Shahjalal islami Bank LTD" },
  { value: "Simanto Bank LTD", label: "Simanto Bank LTD" },
  { value: "Social Islami Bank LTD", label: "Social Islami Bank LTD" },
  { value: "Sonali Bank LTD", label: "Sonali Bank LTD" },
  {
    value: "South Bangla Agriculture and Commerce Bank LTD",
    label: "South Bangla Agriculture and Commerce Bank LTD",
  },
  { value: "Southeast Bank LTD", label: "Southeast Bank LTD" },
  { value: "Standard Bank LTD", label: "Standard Bank LTD" },
  { value: "Standard Chartered Bank", label: "Standard Chartered Bank" },
  { value: "State Bank of India", label: "State Bank of India" },
  { value: "The City Bank LTD", label: "The City Bank LTD" },
  { value: "The Farmers Bank LTD", label: "The Farmers Bank LTD" },
  { value: "The Premier Bank LTD", label: "The Premier Bank LTD" },
  { value: "Trust Bank LTD", label: "Trust Bank LTD" },
  { value: "Union Bank LTD", label: "Union Bank LTD" },
  {
    value: "United Commercial Bank LTD",
    label: "United Commercial Bank LTD",
  },
  { value: "Uttara Bank LTD", label: "Uttara Bank LTD" },
  { value: "Woori Bank", label: "Woori Bank" },
];
const account_type = [
  { value: "Saving", label: "Saving" },
  { value: "Current", label: "Current" },
];
const payment_method = [
  { value: "Mobile Banking", label: "Mobile Banking" },
  { value: "Bank Account", label: "Bank Account" },
  { value: "Cash", label: "Cash" },
];
const mobile_type = [
  { value: "bKash", label: "bKash" },
  { value: "Rocket", label: "Rocket" },
  { value: "Nagad", label: "Nagad" },
];
const bank_payment_option = [
  { value: "Check Voucher", label: "Check Voucher" },
  { value: "Voucher", label: "Voucher" },
];

const active = [
  { value: "true", label: "Yes" },
  { value: "false", label: "No" },
];
const authenticated = [
  { value: "true", label: "Yes" },
  { value: "false", label: "No" },
];
const merchant = [
  { value: "Company", label: "Company" },
  { value: "Individual", label: "Individual" },
];
const doc = [
  { value: "NID", label: "NID" },
  { value: "Passport", label: "Passport" },
  { value: "Driving_Licence", label: "Driving Licence" },
  { value: "None", label: "None" },
];
const WarehouseManagerDetails = ({
  location,
  getManager,
  changeManagerPass,
  getManagerEditData,
  updateWarehouseManager,
  getWarehouses,
}) => {
  const [data, setData] = useState([]);
  const [passVisible, setPassVisible] = useState(false);
  const [passVisibleWarehouse, setPassVisibleWarehouse] = useState(false);

  const [data1, setData1] = useState([]);
  const [datawarehouse, setDatawarehouse] = useState([]);
  const [placement, setPlacement] = useState("left");
  const [visibleC, setVisibleC] = useState(false);
  const [visibleedit, setVisibleEdit] = useState(false);
  const [remark, setRemark] = useState(false);
  const [I, setId] = useState("");
  const [I1, setId1] = useState("");
  const [visibleP, setVisibleP] = useState(false);
  const [auth, setAuth] = useState(false);
  const [Paymoney, setPayMoney] = useState(false);
  const [form] = Form.useForm();
  const [forms] = Form.useForm();
  const [pt, setPaymentType] = useState("");
  const [bpo, setBPO] = useState("");
  const [docimg, setDocImg] = useState(false);
  const [visible, setVisible] = useState(false);
  const [images, setImage] = useState([]);
  const user_id = new URLSearchParams(location.search).get("user_id");
  async function fetchData() {
    const res = await getManager(user_id);

    setData(res);
  }
  async function fetchData1() {
    const res2 = await getManagerEditData(user_id);
    const res = await getWarehouses();
    setData1(res2);
    setDatawarehouse(res);

    form.setFieldsValue({
      user_id: res2.user_id,
      present_address: res2.present_address,
      permanent_address: res2.permanent_address,
      name: res2.name,
      mobile_number: res2.mobile_number,
      email: res2.email,
      warehouse: res2.warehouse.warehouse_id,
    });
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
    fetchData1();
  }, []);
  const handlePassVisible = (id) => {
    setId(id);
    setPassVisible(true);
    // console.log(id);
  };
  const handlePassVisible1 = (id) => {
    setId1(id);
    setPassVisibleWarehouse(true);
    // console.log(id);
  };
  const WarehouseName = (label, value) => {
    return { label, value };
  };

  const onFinish = async (values1) => {
    const code = await changeManagerPass(values1, user_id);

    if (code === 201) {
      setPassVisible(false);

      forms.resetFields();
      fetchData();
      fetchData1();
    }
  };
  const onFinishWarehouse = async (values) => {
    const code = await updateWarehouseManager(values);
    console.log("click");
    if (code === 201) {
      setPassVisibleWarehouse(false);

      // fetchData1()

      form.resetFields();
    }
  };

  let option = [];
  option = datawarehouse
    ? datawarehouse?.map((item) =>
        WarehouseName(item?.name, item?.warehouse_id)
      )
    : null;

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  const handleOk = () => {
    setPassVisible(false);
  };
  const handleOk1 = () => {
    setPassVisibleWarehouse(false);
  };
  const handleOkEdit = () => {
    setVisibleEdit(false);
  };
  const handleCancel = () => {
    setPassVisible(false);
  };
  const handleCancel1 = () => {
    setPassVisibleWarehouse(false);
  };
  const handleCancelEdit = () => {
    setPassVisible(false);
  };

  const chnagepass = (
    <>
      <Modal
        title="Change Manager Password"
        centered
        visible={passVisible}
        cancelText="Close"
        okButtonProps={{ style: { display: "none" } }}
        onCancel={handleCancel}
        height={500}
      >
        <Form
          name="basic"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          style={{ alignSelf: "center" }}
          requiredMark={false}
          form={forms}
        >
          {renderPasswordField("password", "New Password", [
            { required: true, message: "This field is required" },
            {
              pattern: /^[A-Za-z\d@$!%*?&]{8,50}$/,
              message:
                "Minimum 8 character, allowed special characters are @$!%*?&",
            },
          ])}

          {renderTextField("remark", "Remark", [
            {
              required: true,
              message: "This field is required!",
            },
          ])}

          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              Update
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );

  const warehouseManagerEdit = (
    <>
      <Modal
        title="Warehouse Manager Edit"
        centered
        visible={passVisibleWarehouse}
        cancelText="Close"
        okButtonProps={{ style: { display: "none" } }}
        onCancel={handleCancel1}
        height={500}
      >
        <Form
          name="basic"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={onFinishWarehouse}
          onFinishFailed={onFinishFailed}
          style={{ alignSelf: "center" }}
          requiredMark={false}
          form={form}
        >
          <Divider>Warehouse Manager Information</Divider>
          {renderInput("user_id", " Manager ID", [
            { required: true, message: "This field is required" },
          ])}
          {renderInput("name", "Name", [
            { required: true, message: "This field is required" },
          ])}
          {renderInput("permanent_address", " Permanent Address", [
            { required: true, message: "This field is required" },
          ])}
          {renderInput("present_address", " Present Address", [
            { required: true, message: "This field is required" },
          ])}
          {renderInput("mobile_number", " Phone No", [
            { required: true, message: "This field is required" },
            {
              pattern: /^(01)[0-9][0-9]{8}$/,
              message: "Please valid number!",
            },
          ])}
          {renderTextField("email", " Email", [
            { required: true, message: "This field is required" },
          ])}
          {/* {data1
            ? renderSelect(
                "warehouse",
                "Warehouse Name",
                [{ required: true }],
                "Select a option",
                {option},
                "none"
              )
            : null} */}
          <Form.Item
            label="Warehouse Name"
            name="warehouse"
            rules={[{ required: true, message: "Please input your Field!" }]}
          >
            <Select
              showSearch
              style={{ width: "315px" }}
              name="warehouse"
              rules={require}
              placeholder="Select One"
              onChange={(e) => {}}
            >
              {option
                ? option.map((item) => (
                    <Option value={item.value}>{item?.label}</Option>
                  ))
                : null}
            </Select>
          </Form.Item>

          {renderTextField("remark", "Remark", [
            {
              required: true,
              message: "This field is required!",
            },
          ])}

          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              Update
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );

  const pageheader = (
    <>
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Manager Details"
        subTitle="Warehouse Management"
        extra={
          <>
            <Button
              size="small"
              type="primary"
              icon={<EditOutlined />}
              onClick={() => handlePassVisible1(data1.user_id)}
            >
              Edit
            </Button>
            {warehouseManagerEdit}
            <Button
              size="small"
              type="primary"
              icon={<KeyOutlined />}
              onClick={() => handlePassVisible(data.user_id)}
            >
              Change Password
            </Button>
            {chnagepass}
          </>
        }
      ></PageHeader>
      <br></br>
    </>
  );
  const WarehouseDetails = (
    <>
      <Row
        gutter={16}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Col
          xs={24}
          sm={12}
          md={10}
          lg={8}
          xl={6}
          style={{ textAlign: "center" }}
        >
          <Card
            title={"Personal information"}
            style={{
              textAlign: "center",
              backgroundColor: "#820374",
              color: "white",
            }}
            bordered={false}
          >
            Name: {data?.name}
            <br></br>
            Phone No: {data?.mobile_number} <br></br>
            Email: {data?.email} <br></br>
            Status:{" "}
            {data?.authenticated ? (
              <strong>Authanicated</strong>
            ) : (
              <strong>Unuthanicated</strong>
            )}{" "}
            <br></br>
          </Card>
        </Col>
        <br></br>
        <Col
          xs={24}
          sm={12}
          md={10}
          lg={8}
          xl={6}
          style={{ textAlign: "center" }}
        >
          <Card
            title={"Warehouse information"}
            style={{
              textAlign: "center",
              backgroundColor: "#820374",
              color: "white",
            }}
            bordered={false}
          >
            ID: {data?.warehouse?.warehouse_id}
            <br></br>
            Name: {data?.warehouse?.name}
            <br></br>
            Phone No: {data?.warehouse?.mobile_number} <br></br>
            Address: {data?.warehouse?.address} <br></br>
          </Card>
        </Col>
        <br></br>
      </Row>
      <br></br>

      {data?.logs ? (
        <>
          <Title>Logs </Title>
          <br />
          {data?.logs ? (
            <Timeline mode="left">
              {data?.logs?.map((item, index) => (
                <Timeline.Item
                  label={moment
                    .utc(item?.created_at)
                    .format("YYYY-MM-DD hh:mm A")}
                  keys={index}
                >
                  {item?.ops_manager?.name}&nbsp; {item?.message}&nbsp;
                </Timeline.Item>
              ))}
            </Timeline>
          ) : (
            <Empty />
          )}
        </>
      ) : null}
      <br />
    </>
  );

  return (
    <Content>
      {pageheader}
      <br></br>
      {WarehouseDetails}
    </Content>
  );
};

export default connect(null, {
  getManager,
  changeManagerPass,
  getManagerEditData,
  updateWarehouseManager,
  getWarehouses,
})(WarehouseManagerDetails);
