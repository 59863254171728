import { TabletFilled } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Descriptions,
  Divider,
  Form,
  Input,
  PageHeader,
  Row,
  Select,
} from "antd";

import { Content } from "antd/lib/layout/layout";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getAreasForShop } from "../../../actions/area";
import { searchMerchantShop } from "../../../actions/merchant";
import { createShop } from "../../../actions/shop";
import {
  renderInput,
  renderSelect,
  renderTextField,
} from "../../../components/form";
import SelectBox from "../../../components/select";
import history from "../../../history";
const { Option } = Select;
const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 12 },
};
const tailLayout = {
  wrapperCol: { offset: 4, span: 16 },
};

const ShopCreate = ({
  location,
  getAreasForShop,
  createShop,
  searchMerchantShop,
  setMerchantDetails,
}) => {
  const [merchanttype, setMerchantType] = useState("");
  const [pt, setPaymentType] = useState("");
  const [data, setData] = useState([]);
  const [number, setNumber] = useState([]);
  const [shops, setShops] = useState([]);
  const [merchants, setMerchants] = useState([]);
  const [custom, setCustom] = useState(false);

  const [coverageArea, setCoverageArea] = useState(null);

  const [city, setCity] = useState("");
  const [subCity, setSubCity] = useState("");
  const [thana, setThana] = useState("");
  const [area, setArea] = useState("");
  const [cityArr, setCityArr] = useState(null);
  const [subCityArr, setsubCityArr] = useState(null);
  const [thanaArr, setThanaArr] = useState(null);
  const [areaArr, setAreaArr] = useState(null);

  const [form] = Form.useForm();
  const pickup_type = [
    { value: "Electronics", label: "Electronics" },
    { value: "Health & Beauty", label: "Health & Beauty" },
    { value: "Jewelry", label: "Jewelry" },
    { value: "Clothing", label: "Clothing" },
    { value: "Craft", label: "Craft" },
    { value: "Fashion Accessories", label: "Fashion Accessories" },
    { value: "Fragile", label: "Fragile" },
    { value: "Groceries", label: "Groceries" },
    { value: "Others", label: "Others" },
  ];

  const banks = [
    { value: "AB Bank LTD", label: "AB Bank LTD" },
    { value: "Agrani Bank LTD", label: "Agrani Bank LTD" },
    { value: "Al-Arafah Islami Bank LTD", label: "Al-Arafah Islami Bank LTD" },
    { value: "BASIC Bank LTD", label: "BASIC Bank LTD" },
    {
      value: "BDBL (Bangladesh Development Bank LTD)",
      label: "BDBL (Bangladesh Development Bank LTD)",
    },
    { value: "BRAC Bank LTD", label: "BRAC Bank LTD" },
    {
      value: "Bangladesh Commerce Bank LTD",
      label: "Bangladesh Commerce Bank LTD",
    },
    { value: "Bank Al-Falah", label: "Bank Al-Falah" },
    { value: "Bank Asia LTD", label: "Bank Asia LTD" },
    { value: "Citibank NA", label: "Citibank NA" },
    { value: "Commercial Bank of Ceylon", label: "Commercial Bank of Ceylon" },
    { value: "Dhaka Bank LTD", label: "Dhaka Bank LTD" },
    { value: "Dutch Bangla Bank LTD", label: "Dutch Bangla Bank LTD" },
    { value: "Eastern Bank LTD", label: "Eastern Bank LTD" },
    {
      value: "Export Import Bank of Bangladesh LTD",
      label: "Export Import Bank of Bangladesh LTD",
    },
    {
      value: "First Security Islami Bank LTD",
      label: "First Security Islami Bank LTD",
    },
    { value: "HSBC", label: "HSBC" },
    { value: "Habib Bank LTD", label: "Habib Bank LTD" },
    { value: "ICB Islamic Bank LTD", label: "ICB Islamic Bank LTD" },
    { value: "IFIC Bank LTD", label: "IFIC Bank LTD" },
    {
      value: "Islami Bank Bangladesh LTD",
      label: "Islami Bank Bangladesh LTD",
    },
    { value: "Jamuna Bank LTD", label: "Jamuna Bank LTD" },
    { value: "Janata Bank LTD", label: "Janata Bank LTD" },
    { value: "Meghna Bank LTD", label: "Meghna Bank LTD" },
    { value: "Mercantile Bank LTD", label: "Mercantile Bank LTD" },
    { value: "Midland Bank LTD", label: "Midland Bank LTD" },
    { value: "Modhumoti Bank LTD", label: "Modhumoti Bank LTD" },
    { value: "Mutual Trust Bank LTD", label: "Mutual Trust Bank LTD" },
    { value: "NCC Bank LTD", label: "NCC Bank LTD" },
    { value: "NRB Bank LTD", label: "NRB Bank LTD" },
    { value: "NRB Commercial Bank LTD", label: "NRB Commercial Bank LTD" },
    { value: "NRB Global Bank LTD", label: "NRB Global Bank LTD" },
    { value: "National Bank LTD", label: "National Bank LTD" },
    { value: "National Bank of Pakistan", label: "National Bank of Pakistan" },
    { value: "One Bank LTD", label: "One Bank LTD" },
    { value: "Prime Bank LTD", label: "Prime Bank LTD" },
    { value: "Pubali Bank LTD", label: "Pubali Bank LTD" },
    { value: "Rupali Bank LTD", label: "Rupali Bank LTD" },
    { value: "Shahjalal islami Bank LTD", label: "Shahjalal islami Bank LTD" },
    { value: "Simanto Bank LTD", label: "Simanto Bank LTD" },
    { value: "Social Islami Bank LTD", label: "Social Islami Bank LTD" },
    { value: "Sonali Bank LTD", label: "Sonali Bank LTD" },
    {
      value: "South Bangla Agriculture and Commerce Bank LTD",
      label: "South Bangla Agriculture and Commerce Bank LTD",
    },
    { value: "Southeast Bank LTD", label: "Southeast Bank LTD" },
    { value: "Standard Bank LTD", label: "Standard Bank LTD" },
    { value: "Standard Chartered Bank", label: "Standard Chartered Bank" },
    { value: "State Bank of India", label: "State Bank of India" },
    { value: "The City Bank LTD", label: "The City Bank LTD" },
    { value: "The Farmers Bank LTD", label: "The Farmers Bank LTD" },
    { value: "The Premier Bank LTD", label: "The Premier Bank LTD" },
    { value: "Trust Bank LTD", label: "Trust Bank LTD" },
    { value: "Union Bank LTD", label: "Union Bank LTD" },
    {
      value: "United Commercial Bank LTD",
      label: "United Commercial Bank LTD",
    },
    { value: "Uttara Bank LTD", label: "Uttara Bank LTD" },
    { value: "Woori Bank", label: "Woori Bank" },
  ];
  const account_type = [
    { value: "Saving", label: "Saving" },
    { value: "Current", label: "Current" },
  ];
  const payment_method = [
    { value: "Mobile Banking", label: "Mobile Banking" },
    { value: "Bank Account", label: "Bank Account" },
  ];
  const mobile_type = [
    { value: "bKash", label: "bKash" },
    { value: "Nagad", label: "Nagad" },
    { value: "Rocket", label: "Rocket" },
  ];

  const active = [
    { value: "true", label: "Yes" },
    { value: "false", label: "No" },
  ];
  const authenticated = [
    { value: "true", label: "Yes" },
    { value: "false", label: "No" },
  ];
  const merchant = [
    { value: "Company", label: "Company" },
    { value: "Individual", label: "Individual" },
  ];
  const doc = [
    { value: "NID", label: "NID" },
    { value: "Passport", label: "Passport" },
    { value: "Driving_Licence", label: "Driving Licence" },
    { value: "None", label: "None" },
  ];

  useEffect(async () => {
    window.scrollTo(0, 0);
    const res = await getAreasForShop();
    // console.log(res)
    setCoverageArea(res);

    form.setFieldsValue({
      is_custom: false,
    });
  }, []);

  const city_ = async (event) => {
    const cityCode = event;
    if (cityCode === "-1") {
      return;
    }

    const subCity = [...coverageArea?.city[cityCode - 1]?.sub_city];
    setCity(cityCode);
    setsubCityArr(subCity);
    setSubCity("");
    setThana("");
    setArea("");
    setThanaArr(null);
    setAreaArr(null);
    form.setFieldsValue({
      subCity: "",
      thana: "",
      area: "",
    });
    // form.resetFields(
    //   setSubCity(""),
    //   setThana(""),
    //   setArea(""),
    //   setThanaArr(null),
    //   setAreaArr(null)
    // );
  };

  const sub_city = async (event) => {
    const subCode = event;
    if (subCode === "-1") {
      return;
    }
    const thana = [...subCityArr[subCode - 1].thana];

    setSubCity(subCode);
    setThana("");
    setArea("");
    setThanaArr(thana);
    form.setFieldsValue({
      thana: "",
      area: "",
    });
    // setAreaArr(null);
    // form.resetFields(setThana(""), setArea(""), setAreaArr(null));
    // form.setFieldsValue(setThana(""), setArea(""), setAreaArr(null));
  };

  const thana_ = async (event) => {
    const thanaCode = event;
    if (thanaCode === "-1") {
      return;
    }
    const area = [...thanaArr[thanaCode - 1].area];

    setThana(thanaCode);
    setAreaArr(area);
    setArea("");
    form.setFieldsValue({
      area: "",
    });
    // form.setFieldsValue( setArea(""));
    // form.resetFields( setArea(""));
  };

  const area_ = async (event) => {
    const areaCode = event;
    if (areaCode === "-1") {
      return;
    }
    setArea(areaCode);
    // form.resetFields();
  };

  // ========== Cities =========
  // console.log(coverageArea);
  const c_o =
    coverageArea != null
      ? coverageArea?.city?.map((item, id) =>
          item?.active ? (
            <Option key={id} value={item.id}>
              {item?.name}
            </Option>
          ) : null
        )
      : null;

  // ========== Sub cities ===========
  const s_o =
    subCityArr !== null
      ? subCityArr?.map((item, id) =>
          item?.active ? (
            <Option key={id} value={item?.id}>
              {item?.name}
            </Option>
          ) : null
        )
      : null;

  // ========== Thana ===========
  const t_o =
    thanaArr !== null
      ? thanaArr?.map((item, id) =>
          item?.active ? (
            <Option key={id} value={item?.id}>
              {item?.name}
            </Option>
          ) : null
        )
      : null;
  // ========== Area ===========
  const a_o =
    areaArr !== null
      ? areaArr?.map((item, id) =>
          item?.active ? (
            <Option key={id} value={item?.id}>
              {item?.name}
            </Option>
          ) : null
        )
      : null;

  const handlemerchantSearch = async (e) => {
    window.scrollTo(0, 0);
    setNumber(e?.targe?.value);

    let value = e?.target?.value;

    if (value?.length === 11) {
      const res = await searchMerchantShop(value);
      setShops(res?.shops);
      delete res?.shops;
      setMerchants(res);
      setData(value);
      setNumber(value);
    }
    if (value?.length > 11) {
      setData(value);
      // console.log('12 conditon',value)
    }
    if (value?.length < 11) {
      setData(value);
      // console.log('12 conditon',value)
    }
  };

  const onFinish = async (values) => {
    const code = await createShop(values, number);

    if (code === 200) {
      form.resetFields();
    }
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };
  const Pageheader = (
    <>
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Create Shop"
        subTitle="Merchant Management"
        extra={[
          <Button
            key="1"
            type="primary"
            icon={<TabletFilled />}
            onClick={() => history.push("/shop-list")}
          >
            Shop List
          </Button>,
        ]}
      ></PageHeader>
      <br />
    </>
  );
  const SMS = (
    <>
      <Descriptions
        title="Search Mearchant"
        justify="center"
        labelStyle={{
          marginLeft: "150px",
        }}
      >
        <Descriptions.Item
          bordered={true}
          label="Merchant Mobile Number"
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
        >
          <Input
            variant="filled"
            name="merchant"
            label="Merchant Mobile Number"
            style={{ width: "50%" }}
            onChange={(e) => handlemerchantSearch(e)}
          />
        </Descriptions.Item>
      </Descriptions>
      <Descriptions>
        <Descriptions.Item
          bordered={true}
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
        >
          {merchants?.merchantShops?.length === 0 && data?.length > 1 ? (
            <Divider style={{ color: "red" }}>This merchant is Invalid</Divider>
          ) : merchants?.merchantShops?.length > 0 && data?.length > 1 ? (
            <Divider style={{ color: "#820374" }}>
              This Merchant is valid
            </Divider>
          ) : null}
        </Descriptions.Item>
      </Descriptions>
    </>
  );

  return (
    <Content>
      {Pageheader}
      {SMS}
      {data.length > 11 ? (
        <h1 style={{ textAlign: "center", color: "red" }}>
          Invalid Mobile Number
        </h1>
      ) : data.length === 11 ? (
        <>
          {merchant ? (
            <>
              <Form
                {...layout}
                name="basic"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                form={form}
              >
                <Divider style={{ color: "#820374" }}>
                  Merchant varified. Please fill the form
                </Divider>
                {renderInput("name", "Shop Name", [
                  { required: true, message: "Shop name is required" },
                ])}
                {renderInput("email", "Email ", [
                  { required: true, message: "field is required" },
                ])}
                {renderInput("mobile_number", "Mobile Number", [
                  { required: true, message: "Mobile Number is required" },
                ])}
                {renderTextField("pickup_address", " Pickup Address ", [
                  { required: true, message: "Field is required" },
                ])}
                {renderTextField("address", "Address ", [
                  { required: true, message: "Field is required" },
                ])}

                {renderInput("trade_license", "Trade Licence ", [
                  { required: true, message: "country code is required" },
                ])}

                {renderSelect(
                  "authenticated",
                  "Authenticated",
                  [{ required: true }],
                  "Select One",
                  authenticated,
                  "none"
                )}
                {renderSelect(
                  "pickup_type",
                  "Pickup Type",
                  [{ required: true }],
                  "Select One",
                  pickup_type,
                  "none"
                )}
                {renderSelect(
                  "active",
                  "Active",
                  [{ required: true }],
                  "Select One",
                  active,
                  "none"
                )}
                <Divider>Select Area</Divider>

                {/* ================ City =============== */}

                <Form.Item
                  name="city"
                  label={"Select City"}
                  rules={[
                    {
                      required: true,
                      message: "City  is required",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder={"Select one city"}
                    onChange={(e) => city_(e)}
                  >
                    {/* {options} */}
                    {c_o}
                  </Select>
                </Form.Item>
                {/* ================ Sub City ============ */}
                {subCityArr ? (
                  <Form.Item
                    name="subCity"
                    label={"Select Subcity"}
                    rules={[
                      {
                        required: true,
                        message: "Subcity is required",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder={"Select one subcity"}
                      onChange={(e) => sub_city(e)}
                    >
                      {/* {options} */}
                      {s_o}
                    </Select>
                  </Form.Item>
                ) : null}

                {/* ============== Thana ============== */}
                {thanaArr ? (
                  <Form.Item
                    name="thana"
                    label={"Select one thana"}
                    rules={[
                      {
                        required: true,
                        message: "Thana is required",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder={"Select one subcity"}
                      onChange={(e) => thana_(e)}
                    >
                      {/* {options} */}
                      {t_o}
                    </Select>
                  </Form.Item>
                ) : null}
                {/* ============== Area ============= */}
                {areaArr ? (
                  <Form.Item
                    name="area"
                    label={"Select one area"}
                    rules={[
                      {
                        required: true,
                        message: "Area is required",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder={"Select one area"}
                      onChange={(e) => area_(e)}
                    >
                      {/* {options} */}
                      {a_o}
                    </Select>
                  </Form.Item>
                ) : null}
                <Form.Item
                  name="is_custom"
                  valuePropName="checked"
                  wrapperCol={{ offset: 8, span: 16 }}
                  //defaultValue={false}
                >
                  <Checkbox onChange={(e) => setCustom(e.target.checked)}>
                    Select Customized Shop Charge
                  </Checkbox>
                </Form.Item>

                {custom ? (
                  <>
                    <Divider>Delivery Charge</Divider>
                    <Row gutter={16}>
                      <Col xs={12} lg={12} sm={24} md={12}>
                        {renderInput("d_in_city", "In City ", [
                          { required: true, message: "field is required" },
                        ])}
                      </Col>
                      <Col xs={12} lg={12} sm={24} md={12}>
                        {renderInput("d_in_sub_city", "Sub City ", [
                          { required: true, message: "field is required" },
                        ])}
                      </Col>
                      <Col xs={12} lg={12} sm={24} md={12}>
                        {renderInput("d_outer_city", "Outer City ", [
                          { required: true, message: "field is required" },
                        ])}
                      </Col>
                      <Col xs={12} lg={12} sm={24} md={12}>
                        {renderInput("d_ODD", "ODD ", [
                          { required: true, message: "field is required" },
                        ])}
                      </Col>
                    </Row>

                    <Divider>Return Charge</Divider>
                    <Row gutter={16}>
                      <Col xs={12} lg={12} sm={24} md={12}>
                        {renderInput("r_in_city", "In City ", [
                          { required: true, message: "field is required" },
                        ])}
                      </Col>
                      <Col xs={12} lg={12} sm={24} md={12}>
                        {renderInput("r_in_sub_city", "Sub City ", [
                          { required: true, message: "field is required" },
                        ])}
                      </Col>
                      <Col xs={12} lg={12} sm={24} md={12}>
                        {renderInput("r_outer_city", "Outer City ", [
                          { required: true, message: "field is required" },
                        ])}
                      </Col>
                      <Col xs={12} lg={12} sm={24} md={12}>
                        {renderInput("r_ODD", "ODD ", [
                          { required: true, message: "field is required" },
                        ])}
                      </Col>
                    </Row>
                    <Divider>Base Weight (kg)</Divider>
                    <Row gutter={16}>
                      <Col xs={12} lg={12} sm={24} md={12}>
                        {renderInput("base_in_city", "In City ", [
                          { required: true, message: "field is required" },
                        ])}
                      </Col>
                      <Col xs={12} lg={12} sm={24} md={12}>
                        {renderInput("base_in_sub_city", "Sub City ", [
                          { required: true, message: "field is required" },
                        ])}
                      </Col>
                      <Col xs={12} lg={12} sm={24} md={12}>
                        {renderInput("base_outer_city", "Outer City ", [
                          { required: true, message: "field is required" },
                        ])}
                      </Col>
                      <Col xs={12} lg={12} sm={24} md={12}>
                        {renderInput("base_ODD", "ODD ", [
                          { required: true, message: "field is required" },
                        ])}
                      </Col>
                    </Row>
                    <Divider>ECPK</Divider>
                    <Row gutter={16}>
                      <Col xs={12} lg={12} sm={24} md={12}>
                        {renderInput("ecpk_in_city", "In City ", [
                          { required: true, message: "field is required" },
                        ])}
                      </Col>
                      <Col xs={12} lg={12} sm={24} md={12}>
                        {renderInput("ecpk_in_sub_city", "Sub City ", [
                          { required: true, message: "field is required" },
                        ])}
                      </Col>
                      <Col xs={12} lg={12} sm={24} md={12}>
                        {renderInput("ecpk_outer_city", "Outer City ", [
                          { required: true, message: "field is required" },
                        ])}
                      </Col>
                      <Col xs={12} lg={12} sm={24} md={12}>
                        {renderInput("ecpk_ODD", "ODD ", [
                          { required: true, message: "field is required" },
                        ])}
                      </Col>
                    </Row>
                    <Divider>COD</Divider>
                    <Row gutter={16}>
                      <Col xs={12} lg={12} sm={24} md={12}>
                        {renderInput("cod_in_city", "In City ", [
                          { required: true, message: "field is required" },
                        ])}
                      </Col>
                      <Col xs={12} lg={12} sm={24} md={12}>
                        {renderInput("cod_in_sub_city", "Sub City ", [
                          { required: true, message: "field is required" },
                        ])}
                      </Col>
                      <Col xs={12} lg={12} sm={24} md={12}>
                        {renderInput("cod_outer_city", "Outer City ", [
                          { required: true, message: "field is required" },
                        ])}
                      </Col>
                      <Col xs={12} lg={12} sm={24} md={12}>
                        {renderInput("cod_ODD", "ODD ", [
                          { required: true, message: "field is required" },
                        ])}
                      </Col>
                    </Row>
                  </>
                ) : null}
                <SelectBox
                  name="payment_method"
                  label="Payment Method"
                  rules={[
                    { required: true, message: "payment type is required" },
                  ]}
                  options={payment_method}
                  placeholder="Select One"
                  onChange={(e) => {
                    setPaymentType(e);
                  }}
                />
                {pt === "Mobile Banking" ? (
                  <>
                    {renderSelect(
                      "mobile_bank",
                      "Mobile Banking Name*",
                      [{ required: true }],
                      "Select One",
                      mobile_type,
                      "none"
                    )}
                    {renderInput("mobile_banking_account", "Mobile Number ", [
                      { required: true, message: "number is required" },
                    ])}

                    <br></br>
                  </>
                ) : null}
                {pt === "Bank Account" ? (
                  <>
                    {renderSelect(
                      "bank_name",
                      "Bank Name*",
                      [{ required: true }],
                      "Select One",
                      banks,
                      "none"
                    )}
                    {renderSelect(
                      "account_type",
                      "Accont Type*",
                      [{ required: true }],
                      "Select One",
                      account_type,
                      "none"
                    )}
                    {renderInput("account_name", "Account Name", [
                      { required: true, message: "name is required" },
                    ])}
                    {renderInput("branch_name", "Brance Name", [
                      { required: true, message: "name is required" },
                    ])}
                    {renderInput("account_number", "Account Number ", [
                      { required: true, message: "number is required" },
                    ])}
                    {renderInput("routing_number", "Routing Number", [
                      { required: true, message: "number is required" },
                    ])}
                  </>
                ) : null}

                <Form.Item {...tailLayout}>
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </>
          ) : null}
        </>
      ) : data.length < 11 && data.length > 1 ? (
        <h1 style={{ textAlign: "center", color: "red" }}>
          Insert 11 Digit Mobile Number
        </h1>
      ) : null}
    </Content>
  );
};

export default connect(null, {
  getAreasForShop,
  searchMerchantShop,
  createShop,
})(ShopCreate);
