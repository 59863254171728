import {
  CheckCircleOutlined,
  EditOutlined,
  StopOutlined,
} from "@ant-design/icons";
import {
  Button,
  Descriptions,
  Divider,
  Empty,
  Form,
  Modal,
  PageHeader,
  Popconfirm,
  Timeline,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { getRoles } from "../../../actions/role";
import { getUsersById, updateAuth, updateUser } from "../../../actions/user";
import {
  renderDate,
  renderInput,
  renderSelect,
  renderTextField,
} from "../../../components/form";

const tailLayout = {
  wrapperCol: { offset: 4, span: 16 },
};
const gender = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
  { value: "other", label: "Other" },
];
const status = [
  { value: true, label: "Active" },
  { value: false, label: "Inactive" },
];
const Wrapper = styled.div`
  padding: 20px;
`;
const Title = styled.div`
  width: 100%;
  background-color: #820374;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  padding: 5px;
  text-align: center;
`;
const UserDetails = ({
  location,
  getUsersById,
  updateAuth,
  updateUser,
  getRoles,
}) => {
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [Id, setId] = useState("");
  const [data, setData] = useState([]);
  const [form] = Form.useForm();
  const [roles, setRoles] = useState(null);
  const [prevDate, setPrevDate] = useState("");

  useEffect(async () => {
    window.scrollTo(0, 0);
    const id = new URLSearchParams(location.search).get("user");
    const res = await getUsersById(id);
    const res1 = await getRoles(id);

    setRoles(res1);
    if (res1) {
      const result = res;

      // setPrevDate(result?.user?.personal_info?.date_of_birth);
      setData(result);
      form.setFieldsValue({
        name: result?.user?.name,
        authenticated: result?.user?.authenticated,
        email: result?.user?.email,
        mobile_number: result?.user?.mobile_number,
        user_id: result?.user?.user_id,
        date_of_birth: setPrevDate(result?.user?.personal_info?.date_of_birth),
        emergency_number: result?.user?.personal_info?.emergency_number,
        gender: result?.user?.personal_info?.gender,
        permanent_address: result?.user?.personal_info?.permanent_address,
        present_address: result?.user?.personal_info?.present_address,
        role: result?.user?.roles?.role_id,
      });
    }

    setData(res);
  }, []);

  const createRoles = (label, value) => {
    return { label, value };
  };
  const handleRefresh = async () => {
    const id = new URLSearchParams(location.search).get("user");
    const res = await getUsersById(id);
    setData(res);
  };

  const handleStatus = async (role, active) => {
    const code = await updateAuth(role, active);
    if (code === 201) {
      const id = new URLSearchParams(location.search).get("user");
      const res = await getUsersById(id);

      setData(res);
    }
  };
  const onFinish = async (values) => {
    const code = await updateUser(values);
    if (code === 201) {
      handleEditModalVisible();
      setVisibleEdit(false);
      handleRefresh();
    }
  };
  const onFinishFailed = (errorInfo) => {
    // console.log('Failed:', errorInfo);
  };

  const handleEditModalVisible = async (user_id) => {
    setVisibleEdit(true);
    setId(user_id);
  };

  let option = [];
  option = roles
    ? roles.map((item) => createRoles(item.name, item.role_id))
    : null;

  const pageheader = (
    <PageHeader
      ghost={false}
      onBack={() => window.history.back()}
      title="User Details"
      subTitle="Admin Management"
      extra={[
        <>
          <Button
            size="small"
            type="primary"
            icon={<EditOutlined />}
            onClick={() => {
              handleEditModalVisible(data?.user?.user_id);
            }}
          >
            Edit
          </Button>
        </>,
      ]}
    ></PageHeader>
  );

  const detailsuser = (
    <>
      <Wrapper>
        {data?.user ? (
          <>
            <Title>User Information </Title>
            <br />
            <Descriptions>
              <Descriptions.Item label="ID">
                <strong>{data?.user?.user_id}</strong>
              </Descriptions.Item>
              <Descriptions.Item label="Name">
                <strong>{data?.user?.name}</strong>
              </Descriptions.Item>
              <Descriptions.Item label="Mobile Number">
                <strong>{data?.user?.mobile_number}</strong>
              </Descriptions.Item>
              <Descriptions.Item label="Email">
                <strong>{data?.user?.email}</strong>
              </Descriptions.Item>
              <Descriptions.Item label="Address">
                <strong>{data?.user?.personal_info?.present_address}</strong>
              </Descriptions.Item>
              <Descriptions.Item label="Role">
                <strong>{data?.user?.roles?.name}</strong>
              </Descriptions.Item>
              <Descriptions.Item label="BOD">
                <strong>
                  {moment
                    .utc(data?.user?.personal_info?.date_of_birth)
                    .format("YYYY-MM-DD hh:mm A")}
                </strong>
              </Descriptions.Item>
              <Descriptions.Item label="Status">
                <>
                  {data?.user?.authenticated ? (
                    <>
                      {/* <Tag color="#87d068">Active</Tag> */}
                      <Popconfirm
                        title="Are you sure？"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() =>
                          handleStatus(data?.user?.user_id, false)
                        }
                      >
                        <Button
                          size="small"
                          type="primary"
                          icon={<StopOutlined />}
                        >
                          Activate
                        </Button>
                      </Popconfirm>
                    </>
                  ) : (
                    <>
                      {/* <Tag color="#f50">Inactive</Tag> */}
                      <Popconfirm
                        title="Are you sure？"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() =>
                          handleStatus(data?.user?.user_id, true)
                        }
                      >
                        <Button
                          size="small"
                          type="danger"
                          icon={<CheckCircleOutlined />}
                        >
                          Inactive
                        </Button>
                      </Popconfirm>
                    </>
                  )}
                </>
              </Descriptions.Item>
              <br />
            </Descriptions>
          </>
        ) : null}

        {data?.user?.logs ? (
          <>
            <Title>Logs </Title>
            <br />
            {data?.user?.logs ? (
              <Timeline mode="left">
                {data?.user?.logs.map((item, index) => (
                  <Timeline.Item
                    label={moment
                      .utc(item?.created_at)
                      .format("YYYY-MM-DD hh:mm A")}
                    keys={index}
                  >
                    {item?.message}
                  </Timeline.Item>
                ))}
              </Timeline>
            ) : (
              <Empty />
            )}
          </>
        ) : null}
        <br />
      </Wrapper>
    </>
  );

  const EditUser = (
    <>
      <Modal
        title="Update User Informartion"
        centered
        visible={visibleEdit}
        cancelText="Close"
        okButtonProps={{ style: { display: "none" } }}
        onCancel={() => setVisibleEdit(false)}
        width={800}
      >
        {data ? (
          <Form
            name="basic"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            style={{ alignSelf: "center" }}
            requiredMark={false}
            form={form}
          >
            <Form.Item label="User ID" hidden={true} name="user_id">
              {/* <Input size="large" type="hidden"  /> */}
            </Form.Item>
            {/* {renderInput('user_id', 'User ID', [
                    { required: true, message: 'This field is required!' },
                  ])
                } */}
            {renderInput("name", "Full Name", [
              { required: true, message: "This field is required!" },
            ])}
            {renderInput("email", "Email", [
              { required: true, message: "This field is required!" },
            ])}
            {renderInput("mobile_number", "Mobile", [
              { required: true, message: "This field is required!" },
              {
                pattern: /^(01)[0-9][0-9]{8}$/,
                message: "Please valid number!",
              },
            ])}
            {renderSelect(
              "authenticated",
              "Status",
              [{ required: true }],
              "Select a option",
              status,
              "none"
            )}
            {roles
              ? renderSelect(
                  "role",
                  "Role",
                  [{ required: true }],
                  "Select a option",
                  option,
                  "none"
                )
              : null}
            <Divider orientation="center">Personal Information</Divider>

            {renderDate(
              "date_of_birth",
              "Date of Birth",
              [{ required: true, message: "Please input your birth date" }],
              prevDate
            )}
            {renderSelect(
              "gender",
              "Gender",
              [{ required: true }],
              "Select Your Gender",
              gender,
              "none"
            )}
            {renderTextField("permanent_address", "Permanent Address", [
              { required: true, message: "This field is required!" },
            ])}
            {renderTextField("present_address", "Present Address", [
              { required: true, message: "This field is required!" },
            ])}

            {renderInput("emergency_number", "Emergency Number", [
              { required: true, message: "This field is required!" },
              {
                pattern: /^(01)[0-9][0-9]{8}$/,
                message: "Please valid phone number!",
              },
            ])}
            {renderTextField("remark", "Remark", [
              { required: true, message: "This field is required!" },
            ])}

            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit">
                Update
              </Button>
            </Form.Item>
          </Form>
        ) : null}
      </Modal>
    </>
  );

  return (
    <Content>
      {pageheader}
      {detailsuser}
      {EditUser}
    </Content>
  );
};

export default connect(null, {
  getUsersById,
  updateAuth,
  updateUser,
  getRoles,
})(UserDetails);
