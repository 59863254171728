import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 20px;
`;
export const Title = styled.div`
  width: 100%;
  background-color: #820374;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  padding: 5px;
  text-align: center;
`;

export const FormStyle = styled.div`
  width: 100%;
  display: block;
  padding: 5px 0px;
`;
