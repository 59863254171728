import { GoogleOutlined } from "@ant-design/icons";
import { Button, Col, Drawer, Form, Row, Select } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { getDrivers, getDriversOnMap } from "../../../actions/Driver";
import DriverMap from "./DriverMap";
const { Option } = Select;
const mapStyles = {
  map: {
    position: "absolute",
    width: "100%",
    height: "100%",
  },
};
const tailLayout = {
  wrapperCol: { offset: 11, span: 12 },
};
class Map extends Component {
  state = {
    selectdata: null,
    data: {
      driver: null,
      days: null,
    },
    mapdata: null,
    visible: false,
  };
  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
  };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };
  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  async componentDidMount() {
    const res = await this.props.getDrivers();
    this.setState({ selectdata: res });
  }

  createOption = (label, value) => {
    return { label, value };
  };

  doSubmit = async (values) => {
    const res = await this.props.getDriversOnMap(values);
    this.setState({ mapdata: res });
  };

  render() {
    const { drivers } = this.props;
    const { driverOnMap } = this.props;

    let options = [];
    options = this.state.selectdata
      ? this.state.selectdata.map((item) =>
          this.createOption(
            `${item.mobile_number}----` + item.name,
            item.user_id
          )
        )
      : null;

    return (
      <>
        {/* {console.log(this?.state?.mapdata?.locationData)} */}
        <DriverMap drivers={this?.state?.mapdata?.locationData} />

        <Button
          icon={<GoogleOutlined />}
          hoverable
          style={{
            backgroundColor: "#820374",
            color: "white",
            top: "80px",
            marginLeft: "10px",
          }}
          onClick={this.showDrawer}
        >
          Select Driver & Map
        </Button>
        <Drawer
          title={"Select Driver & Map"}
          placement={"left"}
          onClose={this.onClose}
          visible={this.state.visible}
          key={"left"}
          height={300}
        >
          <Form
            name="basic"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 12 }}
            initialValues={{ remember: true }}
            onFinish={this.doSubmit}
            autoComplete="off"
          >
            <Form.Item
              label="Driver"
              name="driver"
              rules={[{ required: true, message: "Please input your driver!" }]}
            >
              <Select
                showSearch
                style={{ width: "250px" }}
                name="driver"
                rules={require}
                placeholder="Select One"
                onChange={(e) => {}}
              >
                {options
                  ? options.map((item) => (
                      <Option value={item.value}>{item?.label}</Option>
                    ))
                  : null}
              </Select>
            </Form.Item>

            <Form.Item
              label="Days"
              name="Days"
              rules={[{ required: true, message: "Please input your Days!" }]}
            >
              <Select
                showSearch
                style={{ width: "250px" }}
                name="days"
                //   label="Day"
                rules={require}
                //   options={this.state.day}
                placeholder="Select One"
                onChange={(e) => {}}
              >
                <Option value="td">Today</Option>
                <Option value="yd">Yestarday</Option>
                <Option value="pd">Previousday</Option>
              </Select>
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
          {/* 
          <Form
            name="basic"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={this.doSubmit}
            onFinishFailed={this.onFinishFailed}
            style={{ alignSelf: "center" }}
            requiredMark={false}
          >
            <Select
              style={{ width: "250px" }}
              name="driver"
              //   label="Driver"
              rules={require}
              options={options}
              placeholder="Select One"
              // value={selectedOption}
              //   onChange={this.handleChange}
            ></Select>
            <br></br>
            <p></p>

            <Select
              style={{ width: "250px" }}
              name="days"
              //   label="Day"
              rules={require}
            //   options={this.state.day}
              placeholder="Select One"
              //   value={selectedOption}
                onChange={(e)=>{console.log(e)}}

            >
              <Option value="td">Today</Option>
              <Option value="yd">Yestarday</Option>
              <Option value="pd">Previousday</Option>
            </Select>
            <br></br>
            <p></p>

            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form> */}

          {this.state.mapdata ? (
            <Row>
              <Col xs={24} sm={24}>
                <p>
                  ID: <strong>{this.state.mapdata.user_id}</strong>
                </p>
              </Col>
              <Col xs={24} sm={24}>
                <p>
                  Name: <strong>{this.state.mapdata.name}</strong>
                </p>
              </Col>
              <Col xs={24} sm={24}>
                <p>
                  Phone: <strong>{this.state.mapdata.mobile_number}</strong>
                </p>
              </Col>
              <Col xs={24} sm={24}>
                <p>
                  Address: <strong>{this.state.mapdata.present_address}</strong>
                </p>
              </Col>
            </Row>
          ) : null}
        </Drawer>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    drivers: state?.drivers?.allDrivers,
    driverOnMap: state?.drivers?.driverOnMap,
  };
};
export default connect(mapStateToProps, { getDrivers, getDriversOnMap })(Map);
