import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Input,
  PageHeader,
  Row,
  Table,
  Tooltip,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { TopMerchantsNumber, TopTenMerchants } from "../../../actions/merchant";

const { RangePicker } = DatePicker;

const TopMerchants = ({ TopMerchantsNumber, TopTenMerchants }) => {
  const [merchantmobile, setMerchantMobile] = useState("");
  const [merchantnumber, setMerchantNumber] = useState(10);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [TTM, setTopTenMerChant] = useState("");

  useEffect(async () => {
    window.scrollTo(0, 0);

    const TTMerchant = await TopMerchantsNumber();
    setTopTenMerChant(TTMerchant);
  }, []);

  const handleDate = (date) => {
    if (date) {
      let startDate = new Date(date[0]._d);
      let endDate = new Date(date[1]._d);
      setStartDate(startDate.toISOString());
      setEndDate(endDate.toISOString());
    } else {
      setStartDate(null);
      setEndDate(null);
      return;
    }
  };
  const handleApiSearch = async () => {
    const res = await TopMerchantsNumber(
      merchantmobile,
      merchantnumber,
      startDate,
      endDate
    );

    setTopTenMerChant(res);
  };

  const searchColumns = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            autoFocus
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
            style={{ marginBottom: 8, display: "block" }}
          ></Input>
          <Button
            onClick={() => {
              confirm();
            }}
            type="primary"
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          &nbsp;
          <Button
            onClick={() => {
              clearFilters();
            }}
            size="small"
            type="danger"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      );
    },
    filterIcon: () => {
      return <SearchOutlined />;
    },
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
  });

  const columnTopTenMerchant = [
    {
      title: "Merchant Name",
      dataIndex: "name",
      key: "name",
      width: "20%",
      ...searchColumns("name"),
    },
    {
      title: "Mobile Number",
      dataIndex: "mobile_number",
      key: "mobile_number",
      width: "20%",
      ...searchColumns("mobile_number"),
    },
    {
      title: "Shop ID",
      dataIndex: "shop_id",
      key: "shop_id",
      width: "20%",
      ...searchColumns("shop_id"),
    },
    {
      title: "Shop",
      dataIndex: "shop",
      key: "shop",
      width: "20%",
      ...searchColumns("shop"),
    },
    {
      title: "Total Order",
      dataIndex: "total_order",
      key: "total_order",
      width: "20%",
      ...searchColumns("total_order"),
    },
  ];
  const createDataTopTenMerchant = (
    name,
    mobile_number,
    shop_id,
    shop,
    total_order
  ) => {
    return { name, mobile_number, shop_id, shop, total_order };
  };

  const rowTopTenMerchant = [
    TTM
      ? TTM?.data?.map((topten) =>
          createDataTopTenMerchant(
            topten?.merchant,
            topten?.mobile_number,
            topten?.shop_id,
            topten?.shop,
            topten?.total_order
          )
        )
      : "",
  ];

  const Pageheader = (
    <PageHeader
      ghost={false}
      onBack={() => window.history.back()}
      title="Top Merchants "
      subTitle="Merchant Management"
      extra={[
        <Tooltip placement="topLeft">
          <strong
            style={{
              color: "white",
              backgroundColor: "#820374",
              padding: "5px",
            }}
          >
            Total Merchants {TTM?.data?.length}
          </strong>
        </Tooltip>,
      ]}
    ></PageHeader>
  );

  const toptenmer = (
    <>
      <Row>
        <Col xs={12} sm={8} md={6} lg={6} xl={4}>
          <Input
            placeholder="Merchant Mobile"
            style={{ width: "99%" }}
            allowClear
            onChange={(e) => setMerchantMobile(e.target.value)}
          />
        </Col>{" "}
        &nbsp; &nbsp;
        <Col xs={12} sm={8} md={6} lg={6} xl={4}>
          <Input
            placeholder="Number of Merchants"
            style={{ width: "99%" }}
            allowClear
            onChange={(e) => setMerchantNumber(e.target.value)}
          />
        </Col>
        &nbsp; &nbsp;
        <Col xs={12} sm={8} md={6} lg={6} xl={6}>
          <RangePicker
            onChange={(e) => {
              handleDate(e);
            }}
            format="YYYY/MM/DD"
            style={{ width: "80%" }}
          />
        </Col>{" "}
        &nbsp; &nbsp;
        <Col xs={12} sm={8} md={2} lg={2} xl={2}>
          <Tooltip title="search" onClick={handleApiSearch}>
            <Button type="primary" shape="circle" icon={<SearchOutlined />} />
          </Tooltip>{" "}
          &nbsp; &nbsp;
        </Col>
      </Row>
      <br></br>
      <Table
        columns={columnTopTenMerchant}
        dataSource={rowTopTenMerchant[0]}
        scroll={{ x: 1000 }}
        sticky
        size="small"
        pagination={true}
        // style={{width:'600px'}}
      />
    </>
  );

  return (
    <Content>
      {Pageheader}
      <br></br>
      {toptenmer}
      <br></br>
    </Content>
  );
};

export default connect(null, {
  TopMerchantsNumber,
  TopTenMerchants,
})(TopMerchants);
