import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { EditOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Empty,
  Form,
  Modal,
  PageHeader,
  Row,
  Select,
  Timeline,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import moment from "moment";
import { getAreas } from "../../../actions/area";
import {
  editParcelDetails,
  getParcelDetails,
  updateCustomOrder,
  updateOrder,
} from "../../../actions/parcel";
import {
  renderInput,
  renderSelect,
  renderTextField,
} from "../../../components/form";

const tailLayout = {
  wrapperCol: { offset: 6, span: 14 },
};
const status = [
  { value: "ND", label: "Regular Delivery" },
  { value: "RD", label: "Same Day Delivery" },
  { value: "", label: "Select One" },
];
const tp_names = [
  { value: "eCourier", label: "E-Courier" },
  { value: "redX", label: "RedX" },
  { value: "paperfly", label: "Paperfly" },
];
const weights = [
  { value: ".5", label: "0-0.5" },
  { value: "1", label: "0.5-1" },
  { value: "2", label: "1-2" },
  { value: "3", label: "2-3" },
  { value: "4", label: "3-4" },
  { value: "5", label: "4-5" },
  { value: "6", label: "5-6" },
  { value: "7", label: "6-7" },
  { value: "8", label: "7-8" },
  { value: "9", label: "8-9" },
  { value: "10", label: "9-10" },
  { value: "11", label: "10-11" },
  { value: "12", label: "11-12" },
  { value: "13", label: "12-13" },
  { value: "14", label: "13-14" },
  { value: "15", label: "14-15" },
];
const product = [
  { value: "Parcel", label: "Parcel" },
  { value: "Document", label: "Document" },
];
const { Option } = Select;

const ParcelDetailsNew = ({
  getParcelDetails,
  editParcelDetails,
  getAreas,
  updateOrder,
  location,
  updateCustomOrder,
  Dshopid,
  Dmerchant,
  Dorderid,
}) => {
  const [data, setData] = useState([]);
  const [Editdata, setEditdata] = useState([]);
  const [RecMobile, setRecMobile] = useState([]);
  const [OrderId, setOrderId] = useState([]);
  const [ShopId, setShopId] = useState([]);
  const [MerchantMobile, setMerchantMobile] = useState([]);

  const [VisibleEdit, setVisibleEdit] = useState(false);
  const [form] = Form.useForm();
  const [city, setCity] = useState("");
  const [subCity, setSubCity] = useState("");
  const [thana, setThana] = useState("");
  const [area, setArea] = useState("");
  const [custom, setCustom] = useState(false);
  const [cityArr, setCityArr] = useState(null);
  const [subCityArr, setSubCityArr] = useState(null);
  const [thanaArr, setThanaArr] = useState(null);
  const [areaArr, setAreaArr] = useState(null);
  const [coverageArea, setCoverageArea] = useState(null);

  const [amountCollect, setAmountCollect] = useState([]);
  const [delivercharge, setDeliveryCharge] = useState([]);
  const [codcharge, setCodCharge] = useState([]);

  const mobile = new URLSearchParams(location?.search).get("merchant");
  const order = new URLSearchParams(location?.search).get("order");
  const shop = new URLSearchParams(location?.search).get("shop");

  useEffect(async () => {
    window.scrollTo(0, 0);
    if (Dorderid) {
      const res = await getParcelDetails(Dmerchant, Dorderid, Dshopid);
      setData(res);
      setRecMobile(res?.recipient_mobile_number);
      setOrderId(res?.order_id);
      setShopId(res?.shop.shop_id);
      setMerchantMobile(res?.merchant.mobile_number);
    }
    if (location) {
      // const orderl = new URLSearchParams(location?.search).get("order");
      // const merchantl = new URLSearchParams(location?.search).get("merchant");
      // const shopl = new URLSearchParams(location?.search).get("shop");
      const res = await getParcelDetails(Dmerchant, Dorderid, Dshopid);
      setData(res);
      setRecMobile(res?.recipient_mobile_number);
      setOrderId(res?.order_id);
      setShopId(res?.shop.shop_id);
      setMerchantMobile(res?.merchant.mobile_number);
      // const id = new URLSearchParams(location?.search).get("transaction_id");
      // const res = await getTransactionDetails(id);
      // setData(res);
    }

    // console.log('1st',res);
  }, [Dorderid]);

  const Editparcelget = async () => {
    const area = await getAreas();
    setCoverageArea(area);
    const res1 = await editParcelDetails(
      RecMobile,
      OrderId,
      ShopId,
      MerchantMobile
    );
    const splitAreaCode = res1?.order?.recipient_area_code?.split("-");

    setCity(splitAreaCode[0]);
    setSubCity(splitAreaCode[1]);
    setThana(splitAreaCode[2]);
    setArea(splitAreaCode[3]);

    setSubCityArr(area?.city[splitAreaCode[0] - 1]?.sub_city);

    setThanaArr(
      area?.city[splitAreaCode[0] - 1]?.sub_city[splitAreaCode[1] - 1].thana
    );

    setAreaArr(
      area?.city[splitAreaCode[0] - 1]?.sub_city[splitAreaCode[1] - 1].thana[
        splitAreaCode[2] - 1
      ].area
    );

    form.setFieldsValue({
      product_type: res1?.order?.product_type,
      merchant: mobile,
      shop_id: res1?.order?.shop?.shop_id,
      order_id: res1?.order?.order_id,
      delivery_type: res1?.order?.delivery_time,
      merchant_order_id: res1?.order?.merchant_order_id,
      recipient_name: res1?.order?.recipient_name,
      recipient_mobile_number: res1?.order?.recipient_mobile_number,
      recipient_address: res1?.order?.recipient_address,
      item_actual_price: res1?.order?.charges?.item_actual_price,
      amount_to_collect: res1?.order?.charges?.amount_to_collect,
      quantity: res1?.order?.quantity,
      cod: res1?.order?.charges?.cod,
      return_charge: res1?.order?.charges?.return_charge,
      delivery_charge: res1?.order?.charges?.delivery_charge,
      is_tp: res1?.order?.is_tp,
      tp_name: res1?.order?.tp_name,
      tp_charge: res1?.order?.charges?.tp_charge,

      weight: res1?.order?.weight / 1000,
      note: res1?.order?.note,
      item_description: res1?.order?.item_description,

      city: splitAreaCode[0],
      subCity: splitAreaCode[1],
      thana: splitAreaCode[2],
      area: splitAreaCode[3],
      recipient_area_code: city + "-" + subCity + "-" + thana + "-" + area,
    });

    setCustom(res1?.order?.is_tp);

    setEditdata(res1);
  };
  const handleEditModalVisible = async () => {
    Editparcelget();
    setVisibleEdit(true);
  };

  const onFinishEdit = async (values) => {
    const code = await updateOrder(values, Dmerchant, custom);

    if (code === 201) {
      setVisibleEdit(false);
      const res = await getParcelDetails(mobile, order, shop);
    }
  };
  const onFinishEditCustom = async (values) => {
    // console.log(values)

    const code = await updateCustomOrder(values, Dmerchant, custom);

    if (code === 201) {
      setVisibleEdit(false);
      const res = await getParcelDetails(mobile, order, shop);
    }
  };
  const handleOkEdit = () => {
    setVisibleEdit(false);
  };
  const onFinishFailedEdit = (errorInfo) => {
    // console.log('Failed:', errorInfo);
  };

  const handleCancelEdit = () => {
    setVisibleEdit(false);
  };
  const city_ = async (event) => {
    const cityCode = event;
    if (cityCode === "-1") {
      return;
    }

    const subCity = [...coverageArea?.city[cityCode - 1]?.sub_city];
    setCity(cityCode);
    setSubCityArr(subCity);
    setSubCity("");
    setThana("");
    setArea("");
    setThanaArr(null);
    setAreaArr(null);
    form.setFieldsValue({
      subCity: "",
      thana: "",
      area: "",
    });
    // form.resetFields(
    //   setSubCity(""),
    //   setThana(""),
    //   setArea(""),
    //   setThanaArr(null),
    //   setAreaArr(null)
    // );
  };

  const sub_city = async (event) => {
    const subCode = event;
    if (subCode === "-1") {
      return;
    }
    const thana = [...subCityArr[subCode - 1].thana];

    setSubCity(subCode);
    setThana("");
    setArea("");
    setThanaArr(thana);
    form.setFieldsValue({
      thana: "",
      area: "",
    });
    // setAreaArr(null);
    // form.resetFields(setThana(""), setArea(""), setAreaArr(null));
    // form.setFieldsValue(setThana(""), setArea(""), setAreaArr(null));
  };

  const thana_ = async (event) => {
    const thanaCode = event;
    if (thanaCode === "-1") {
      return;
    }
    const area = [...thanaArr[thanaCode - 1].area];

    setThana(thanaCode);
    setAreaArr(area);
    setArea("");
    form.setFieldsValue({
      area: "",
    });
    // form.setFieldsValue( setArea(""));
    // form.resetFields( setArea(""));
  };

  const area_ = async (event) => {
    const areaCode = event;
    if (areaCode === "-1") {
      return;
    }
    setArea(areaCode);
    // form.resetFields();
  };

  // ========== Cities =========
  // console.log(coverageArea);

  const c_o =
    coverageArea != null
      ? coverageArea?.city?.map((item, id) => {
          return item?.active ? (
            <Option key={id} value={`${item.id}`}>
              {item?.name}
            </Option>
          ) : null;
        })
      : null;

  // ========== Sub cities ===========
  const s_o =
    subCityArr !== null
      ? subCityArr?.map((item, id) =>
          item?.active ? (
            <Option key={id} value={`${item.id}`}>
              {item?.name}
            </Option>
          ) : null
        )
      : null;

  // ========== Thana ===========
  const t_o =
    thanaArr !== null
      ? thanaArr?.map((item, id) =>
          item?.active ? (
            <Option key={id} value={`${item.id}`}>
              {item?.name}
            </Option>
          ) : null
        )
      : null;
  // ========== Area ===========
  const a_o =
    areaArr !== null
      ? areaArr?.map((item, id) => {
          return item?.active ? (
            <Option key={id} value={`${item.id}`}>
              {item?.name}
            </Option>
          ) : null;
        })
      : null;

  const Pageheader = (
    <>
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Parcel Details "
        subTitle="Parcel Management"
        extra={[
          <>
            {data.state === "Canceled" ||
            data.type === "RO" ||
            data.state === "Returned" ||
            data.state === "Delivered" ? null : (
              <Button
                size="small"
                type="primary"
                icon={<EditOutlined />}
                onClick={() => {
                  handleEditModalVisible();
                }}
              >
                Edit
              </Button>
            )}
          </>,
        ]}
      ></PageHeader>
      <br></br>
    </>
  );
  const Parcel_Details = (
    <>
      <Card hoverable style={{ backgroundColor: "#820374", color: "white" }}>
        <div style={{ display: "block" }}>
          <div>
            Parcel Id : {data?.order_id}&nbsp; {data?.state}
          </div>
          {data?.otp ? (
            <strong style={{ float: "right" }}>OTP : {data?.otp}</strong>
          ) : null}
        </div>
      </Card>
      <br></br>
      <Row gutter={16}>
        {data ? (
          <Col xl={12} lg={12} sm={24} md={24} es={24}>
            <Card
              hoverable
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                maxHeight: 300,
                minHeight: 300,
              }}
            >
              <Divider>Parcel Information</Divider>
              {data?.merchant_order_id ? (
                <p>
                  ID : <strong>{data?.merchant_order_id}</strong>
                </p>
              ) : null}
              {data?.weight ? (
                <p>
                  Weight : <strong>{data?.weight} gm</strong>
                </p>
              ) : null}
              {data?.product_type ? (
                <p>
                  Type : <strong>{data?.product_type}</strong>
                </p>
              ) : null}
              {data?.quantity ? (
                <p>
                  Quantity : <strong>{data?.quantity}</strong>{" "}
                </p>
              ) : null}
              {data?.item_description ? (
                <p>
                  Description : <strong>{data?.item_description}</strong>
                </p>
              ) : null}

              {data?.note ? (
                <p>
                  Note :<strong> {data?.note}</strong>
                </p>
              ) : null}
              {data?.delivery_time ? (
                <p>
                  Delivery time :<strong> {data?.delivery_time}</strong>
                </p>
              ) : null}
            </Card>
            <br></br>
          </Col>
        ) : null}
        <br></br>
        {data ? (
          <Col xl={12} lg={12} sm={24} md={24} es={24}>
            <Card
              hoverable
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                maxHeight: 300,
                minHeight: 300,
              }}
            >
              <Divider>Customer Information</Divider>
              {data?.recipient_name ? (
                <p>
                  Name: <strong>{data?.recipient_name}</strong>
                </p>
              ) : null}
              {data?.recipient_mobile_number ? (
                <p>
                  phone No : <strong>{data?.recipient_mobile_number} </strong>
                </p>
              ) : null}
              {data?.recipient_address ? (
                <p>
                  Address : <strong>{data?.recipient_address}</strong>
                </p>
              ) : null}
              {data?.receipt_no ? (
                <p>
                  Receipt No : <strong>{data?.receipt_no}</strong>{" "}
                </p>
              ) : null}
            </Card>
            <br></br>
          </Col>
        ) : null}
        <br></br>
        {data?.merchant ? (
          <Col xl={12} lg={12} sm={24} md={24} es={24}>
            <Card
              hoverable
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                maxHeight: 300,
                minHeight: 300,
              }}
            >
              <Divider>Merchant Information</Divider>
              {data?.merchant?.name ? (
                <p>
                  Name: <strong>{data?.merchant?.name}</strong>
                </p>
              ) : null}
              {data?.merchant?.mobile_number ? (
                <p>
                  phone No : <strong>{data?.merchant?.mobile_number} </strong>
                </p>
              ) : null}
              {data?.merchant?.email ? (
                <p>
                  Address : <strong>{data?.merchant?.email}</strong>
                </p>
              ) : null}
              {data?.merchant?.address ? (
                <p>
                  Address : <strong>{data?.merchant?.address}</strong>{" "}
                </p>
              ) : null}
            </Card>
            <br></br>
          </Col>
        ) : null}
        <br></br>
        {data?.shop ? (
          <Col xl={12} lg={12} sm={24} md={24} es={24}>
            <Card
              hoverable
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                maxHeight: 300,
                minHeight: 300,
              }}
            >
              <Divider>Store Information</Divider>
              {data?.order_id ? (
                <p>
                  ID: <strong>{data?.order_id}</strong>
                </p>
              ) : null}
              {data?.shop?.name ? (
                <p>
                  Name : <strong>{data?.shop?.name} </strong>
                </p>
              ) : null}
              {data?.shop?.mobile_number ? (
                <p>
                  Phone No : <strong>{data?.shop?.mobile_number}</strong>
                </p>
              ) : null}
              {data?.shop?.address ? (
                <p>
                  Address : <strong>{data?.shop?.address}</strong>{" "}
                </p>
              ) : null}
            </Card>
            <br></br>
          </Col>
        ) : null}
        <br></br>
        {data?.pickup_driver ? (
          <Col xl={12} lg={12} sm={24} md={24} es={24}>
            <Card
              hoverable
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                maxHeight: 300,
                minHeight: 300,
              }}
            >
              <Divider>Pickup Driver Information</Divider>
              {data?.pickup_driver?.user_id ? (
                <p>
                  ID: <strong>{data?.pickup_driver?.user_id}</strong>
                </p>
              ) : null}
              {data?.pickup_driver?.name ? (
                <p>
                  Name : <strong>{data?.pickup_driver?.name} </strong>
                </p>
              ) : null}
              {data?.pickup_driver?.mobile_number ? (
                <p>
                  Phone No :{" "}
                  <strong>{data?.pickup_driver?.mobile_number}</strong>
                </p>
              ) : null}
            </Card>
            <br></br>
          </Col>
        ) : null}
        <br></br>
        {data?.delivery_driver ? (
          <Col xl={12} lg={12} sm={24} md={24} es={24}>
            <Card
              hoverable
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                maxHeight: 300,
                minHeight: 300,
              }}
            >
              <Divider>Delivery Driver Information</Divider>
              {data?.delivery_driver?.user_id ? (
                <p>
                  ID: <strong>{data?.delivery_driver?.user_id}</strong>
                </p>
              ) : null}
              {data?.delivery_driver?.name ? (
                <p>
                  Name : <strong>{data?.delivery_driver?.name} </strong>
                </p>
              ) : null}
              {data?.delivery_driver?.mobile_number ? (
                <p>
                  Phone No :{" "}
                  <strong>{data?.delivery_driver?.mobile_number}</strong>
                </p>
              ) : null}
            </Card>
            <br></br>
          </Col>
        ) : null}
        <br></br>
        {data?.hold_by_warehouse ? (
          <Col xl={12} lg={12} sm={24} md={24} es={24}>
            <Card
              hoverable
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                maxHeight: 300,
                minHeight: 300,
              }}
            >
              <Divider>Warehouse Information </Divider>
              {data?.hold_by_warehouse?.warehouse_id ? (
                <p>
                  ID: <strong>{data?.hold_by_warehouse?.warehouse_id}</strong>
                </p>
              ) : null}
              {data?.hold_by_warehouse?.name ? (
                <p>
                  Name : <strong>{data?.hold_by_warehouse?.name} </strong>
                </p>
              ) : null}
              {data?.hold_by_warehouse?.mobile_number ? (
                <p>
                  Phone No :{" "}
                  <strong>{data?.hold_by_warehouse?.mobile_number}</strong>
                </p>
              ) : null}
              {data?.hold_by_warehouse?.address ? (
                <p>
                  Address : <strong>{data?.hold_by_warehouse?.address}</strong>
                </p>
              ) : null}
            </Card>
            <br></br>
          </Col>
        ) : null}
        <br></br>
        {data?.charges ? (
          <Col xl={12} lg={12} sm={24} md={24} es={24}>
            <Card
              hoverable
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                maxHeight: 300,
                minHeight: 300,
              }}
            >
              <Divider>Payment Information</Divider>
              {data?.charges?.cod ? (
                <p>
                  COD: <strong>{data?.charges?.cod}</strong>
                </p>
              ) : null}
              {data?.charges?.amount_to_collect ? (
                <p>
                  Amount Collection :{" "}
                  <strong>{data?.charges?.amount_to_collect} </strong>
                </p>
              ) : null}
              {data?.charges?.delivery_charge ? (
                <p>
                  Delivery Charge :{" "}
                  <strong>{data?.charges?.delivery_charge}</strong>
                </p>
              ) : null}
              {data?.charges?.additional_cost ? (
                <p>
                  Additional cost :{" "}
                  <strong>{data?.charges?.additional_cost}</strong>
                </p>
              ) : null}
              {data?.charges?.item_actual_price ? (
                <p>
                  Actual Price :{" "}
                  <strong>{data?.charges?.item_actual_price}</strong>
                </p>
              ) : null}
              {data?.charges?.payable_amount ? (
                <p>
                  Payable Price :{" "}
                  <strong>{data?.charges?.payable_amount}</strong>
                </p>
              ) : null}
              {data?.charges?.return_charge ? (
                <p>
                  Return Charge :{" "}
                  <strong>{data?.charges?.return_charge}</strong>
                </p>
              ) : null}
            </Card>
            <br></br>
          </Col>
        ) : null}
        <br></br>
        {data?.hold_by_agent ? (
          <Col xl={12} lg={12} sm={24} md={24} es={24}>
            <Card
              hoverable
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                maxHeight: 300,
                minHeight: 300,
              }}
            >
              <Divider>Agent Information</Divider>
              {data?.hold_by_agent?.user_id ? (
                <p>
                  ID: <strong>{data?.hold_by_agent?.user_id}</strong>
                </p>
              ) : null}
              {data?.hold_by_agent?.name ? (
                <p>
                  Name : <strong>{data?.hold_by_agent?.name} </strong>
                </p>
              ) : null}
              {data?.hold_by_agent?.mobile_number ? (
                <p>
                  Phone No :{" "}
                  <strong>{data?.hold_by_agent?.mobile_number}</strong>
                </p>
              ) : null}
            </Card>
          </Col>
        ) : null}
        <br></br>

        {data?.logs ? (
          <Col xl={12} lg={12} sm={24} md={24} es={24}>
            <Divider>Logs </Divider>
            <br />
            {data?.logs ? (
              <Timeline mode="left">
                {data?.logs.map((item, index) => (
                  <Timeline.Item
                    label={moment
                      .utc(item?.created_at)
                      .format("YYYY-MM-DD hh:mm A")}
                    keys={index}
                  >
                    {item?.message}
                  </Timeline.Item>
                ))}
              </Timeline>
            ) : (
              <Empty />
            )}
          </Col>
        ) : null}
        {data?.warehouse_logs ? (
          <Col xl={12} lg={12} sm={24} md={24} es={24}>
            <Divider>warehouse logs </Divider>
            <br />
            {data?.warehouse_logs ? (
              <Timeline mode="left">
                {data?.warehouse_logs.map((item, index) => (
                  <Timeline.Item
                    label={moment
                      .utc(item?.created_at)
                      .format("YYYY-MM-DD hh:mm A")}
                    keys={index}
                  >
                    {item?.message}
                    <br />
                    {item?.ops_manager
                      ? `Manager: ${item?.ops_manager?.name} (${item?.ops_manager?.user_id})`
                      : ""}
                    <br />
                    {item?.agent
                      ? `Rider: ${item?.agent?.name} (${item?.agent?.user_id})`
                      : ""}
                  </Timeline.Item>
                ))}
              </Timeline>
            ) : (
              <Empty />
            )}
          </Col>
        ) : null}
      </Row>
    </>
  );

  const ParcelEdits = data.manual ? (
    <>
      <Modal
        title="Custom Parcel Edit"
        centered
        visible={VisibleEdit}
        cancelText="Close"
        okButtonProps={{ style: { display: "none" } }}
        onCancel={handleCancelEdit}
        width={600}
      >
        {Editdata ? (
          <Form
            name="basic"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onFinishEditCustom}
            onFinishFailed={onFinishFailedEdit}
            style={{ alignSelf: "center" }}
            requiredMark={false}
            form={form}
          >
            <Form.Item label="User ID" hidden={true} name="shop_id"></Form.Item>
            <Form.Item
              label="Order ID"
              hidden={true}
              name="order_id"
            ></Form.Item>
            <Form.Item
              label="Merchant Mobile ID"
              hidden={true}
              name="merchant"
            ></Form.Item>
            <Divider orientation="center">Order ID & Type </Divider>

            {renderSelect(
              "product_type",
              "Product Type",
              [{ required: true }],
              "Select a option",
              product,
              "none"
            )}
            {renderSelect(
              "delivery_type",
              "delivery Type",
              [{ required: true }],
              "Select a option",
              status,
              "none"
            )}
            {renderInput("merchant_order_id", "Order Ref No", [
              { required: true, message: "This field is required!" },
            ])}
            <Divider>Select Area</Divider>

            {/* ================ City =============== */}

            <Form.Item
              name="city"
              label={"Select City"}
              rules={[
                {
                  required: true,
                  message: "City  is required",
                },
              ]}
            >
              <Select
                showSearch
                placeholder={"Select one city"}
                onChange={(e) => city_(e)}
                value={city}
              >
                <Option value="-1">Select City</Option>
                {c_o}
              </Select>
            </Form.Item>
            {/* ================ Sub City ============ */}
            {subCityArr ? (
              <Form.Item
                name="subCity"
                label={"Select Subcity"}
                rules={[
                  {
                    required: true,
                    message: "Subcity is required",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder={"Select one subcity"}
                  onChange={(e) => sub_city(e)}
                  value={subCity}
                >
                  {/* {options} */}
                  {s_o}
                </Select>
              </Form.Item>
            ) : null}

            {/* ============== Thana ============== */}
            {thanaArr ? (
              <Form.Item
                name="thana"
                label={"Select one thana"}
                rules={[
                  {
                    required: true,
                    message: "Thana is required",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder={"Select one subcity"}
                  onChange={(e) => thana_(e)}
                  value={thana}
                >
                  {/* {options} */}
                  {t_o}
                </Select>
              </Form.Item>
            ) : null}
            {/* ============== Area ============= */}
            {areaArr ? (
              <Form.Item
                name="area"
                label={"Select one area"}
                rules={[
                  {
                    required: true,
                    message: "Area is required",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder={"Select one area"}
                  onChange={(e) => area_(e)}
                  value={area}
                >
                  {/* {options} */}
                  {a_o}
                </Select>
              </Form.Item>
            ) : null}
            <Divider orientation="center">Customer Details </Divider>

            {renderInput("recipient_name", "Recipient Name", [
              { required: true, message: "This field is required!" },
            ])}
            {renderInput("recipient_mobile_number", "Recipient Phone", [
              { required: true, message: "This field is required!" },
              {
                pattern: /^(01)[0-9][0-9]{8}$/,
                message: "Please valid number!",
              },
            ])}
            {renderTextField("recipient_address", "Recipient Addrerss", [
              { required: true, message: "This field is required!" },
            ])}
            <Divider>Product Details</Divider>
            {renderInput("item_actual_price", "Item Actual Price", [
              { required: true, message: "This field is required!" },
            ])}
            {renderInput("amount_to_collect", "Collected  Amount", [
              { required: true, message: "This field is required!" },
            ])}
            {renderInput("item_description", "Item Description", [
              { required: true, message: "This field is required!" },
            ])}
            {renderInput("delivery_charge", "Delivery charge", [
              { required: true, message: "This field is required!" },
            ])}
            {renderInput("cod", "COD Charge", [
              { required: true, message: "This field is required!" },
            ])}
            {renderInput("return_charge", "Return Charge", [
              { required: true, message: "This field is required!" },
            ])}
            {renderInput("note", "Note", [
              { required: true, message: "This field is required!" },
            ])}
            {renderInput("quantity", "Quantity", [
              { required: true, message: "This field is required!" },
            ])}

            {renderSelect(
              "weight",
              "Product Weight (kg)",
              [{ required: true }],
              "Select a option",
              weights,
              "none"
            )}
            <Form.Item
              name="is_tp"
              valuePropName="checked"
              wrapperCol={{ offset: 8, span: 16 }}
              //defaultValue={false}
            >
              <Checkbox onChange={(e) => setCustom(e.target.checked)}>
                Select Third Party
              </Checkbox>
            </Form.Item>
            {custom ? (
              <>
                {renderSelect(
                  "tp_name",
                  "Third Party Name",
                  [{ required: true }],
                  "Select a option",
                  tp_names,
                  "none"
                )}
                {renderInput("tp_charge", "Third Party Charge", [
                  { required: true, message: "This field is required!" },
                ])}
              </>
            ) : null}

            {renderTextField("remark", "Remark", [
              {
                required: true,
                message: "This field is required!",
              },
            ])}

            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit">
                Update
              </Button>
            </Form.Item>
          </Form>
        ) : null}
      </Modal>
    </>
  ) : (
    <>
      <Modal
        title="Parcel Edit"
        centered
        visible={VisibleEdit}
        cancelText="Close"
        okButtonProps={{ style: { display: "none" } }}
        onCancel={handleCancelEdit}
        width={600}
      >
        {Editdata ? (
          <Form
            name="basic"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onFinishEdit}
            onFinishFailed={onFinishFailedEdit}
            style={{ alignSelf: "center" }}
            requiredMark={false}
            form={form}
          >
            <Form.Item label="User ID" hidden={true} name="shop_id"></Form.Item>
            <Form.Item
              label="Order ID"
              hidden={true}
              name="order_id"
            ></Form.Item>
            <Form.Item
              label="Merchant Mobile ID"
              hidden={true}
              name="merchant"
            ></Form.Item>
            <Divider orientation="center">Order ID & Type </Divider>

            {renderSelect(
              "product_type",
              "Product Type",
              [{ required: true }],
              "Select a option",
              product,
              "none"
            )}
            {renderSelect(
              "delivery_type",
              "delivery Type",
              [{ required: true }],
              "Select a option",
              status,
              "none"
            )}
            {renderInput("merchant_order_id", "Order Ref No", [
              { required: true, message: "This field is required!" },
            ])}
            <Divider>Select Area</Divider>

            {/* ================ City =============== */}

            <Form.Item
              name="city"
              label={"Select City"}
              rules={[
                {
                  required: true,
                  message: "City  is required",
                },
              ]}
            >
              <Select
                showSearch
                placeholder={"Select one city"}
                onChange={(e) => city_(e)}
                value={city}
              >
                <Option value="-1">Select City</Option>
                {c_o}
              </Select>
            </Form.Item>
            {/* ================ Sub City ============ */}
            {subCityArr ? (
              <Form.Item
                name="subCity"
                label={"Select Subcity"}
                rules={[
                  {
                    required: true,
                    message: "Subcity is required",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder={"Select one subcity"}
                  onChange={(e) => sub_city(e)}
                  value={subCity}
                >
                  {/* {options} */}
                  {s_o}
                </Select>
              </Form.Item>
            ) : null}

            {/* ============== Thana ============== */}
            {thanaArr ? (
              <Form.Item
                name="thana"
                label={"Select one thana"}
                rules={[
                  {
                    required: true,
                    message: "Thana is required",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder={"Select one subcity"}
                  onChange={(e) => thana_(e)}
                  value={thana}
                >
                  {/* {options} */}
                  {t_o}
                </Select>
              </Form.Item>
            ) : null}
            {/* ============== Area ============= */}
            {areaArr ? (
              <Form.Item
                name="area"
                label={"Select one area"}
                rules={[
                  {
                    required: true,
                    message: "Area is required",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder={"Select one area"}
                  onChange={(e) => area_(e)}
                  value={area}
                >
                  {/* {options} */}
                  {a_o}
                </Select>
              </Form.Item>
            ) : null}
            <Divider orientation="center">Customer Details </Divider>

            {renderInput("recipient_name", "Recipient Name", [
              { required: true, message: "This field is required!" },
            ])}
            {renderInput("recipient_mobile_number", "Recipient Phone", [
              { required: true, message: "This field is required!" },
              {
                pattern: /^(01)[0-9][0-9]{8}$/,
                message: "Please valid number!",
              },
            ])}
            {renderTextField("recipient_address", "Recipient Addrerss", [
              { required: true, message: "This field is required!" },
            ])}
            <Divider>Product Details</Divider>
            {renderInput("item_actual_price", "Item Actual Price", [
              { required: true, message: "This field is required!" },
            ])}
            {renderInput("amount_to_collect", "Collected  Amount", [
              { required: true, message: "This field is required!" },
            ])}
            {renderInput("item_description", "Item Description", [
              { required: true, message: "This field is required!" },
            ])}
            {renderInput("note", "Note", [
              { required: true, message: "This field is required!" },
            ])}
            {renderInput("quantity", "Quantity", [
              { required: true, message: "This field is required!" },
            ])}

            {renderSelect(
              "weight",
              "Product Weight (kg)",
              [{ required: true }],
              "Select a option",
              weights,
              "none"
            )}
            <Form.Item
              name="is_tp"
              valuePropName="checked"
              wrapperCol={{ offset: 8, span: 16 }}
              //defaultValue={false}
            >
              <Checkbox onChange={(e) => setCustom(e.target.checked)}>
                Select Third Party
              </Checkbox>
            </Form.Item>

            {custom ? (
              <>
                {renderSelect(
                  "tp_name",
                  "Third Party Name",
                  [{ required: true }],
                  "Select a option",
                  tp_names,
                  "none"
                )}
                {renderInput("tp_charge", "Third Party Charge", [
                  { required: true, message: "This field is required!" },
                ])}
              </>
            ) : null}

            {renderTextField("remark", "Remark", [
              {
                required: true,
                message: "This field is required!",
              },
            ])}

            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit">
                Update
              </Button>
            </Form.Item>
          </Form>
        ) : null}
      </Modal>
    </>
  );

  //   console.log(form.getFieldValue());

  return (
    <Content>
      {Pageheader}
      <br></br>
      {Parcel_Details}
      <br></br>
      {ParcelEdits}
    </Content>
  );
};

export default connect(null, {
  getParcelDetails,
  editParcelDetails,
  updateOrder,
  getAreas,
  updateCustomOrder,
})(ParcelDetailsNew);
