import { Card, Col, PageHeader, Row } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getAllWarehouses } from "../../../actions/warehouse";
import history from "../../../history";

const WareHouse = ({ getAllWarehouses }) => {
  const [data, setData] = useState([]);

  useEffect(async () => {
    window.scrollTo(0, 0);
    const res = await getAllWarehouses();
    setData(res);
  }, []);
  const handleVisible = async (warehouse_id) => {
    history.push(`/warehouse-details?warehouse_id=${warehouse_id}`);
  };

  const Pageheader = (
    <PageHeader
      ghost={false}
      onBack={() => window.history.back()}
      title="Warehouse List"
      subTitle="Finance Management"
    ></PageHeader>
  );
  const CARD = (
    <>
      <Row gutter={16} justify="center">
        {data
          ? data.map((item, id) => (
              <>
                <Col xs={6} lg={8} sm={24} md={12}>
                  &nbsp;
                  <Card
                    hoverable
                    style={{
                      width: "250px",
                      backgroundColor: "#820374",
                      color: "white",
                    }}
                    onClick={() => {
                      handleVisible(item?.warehouse_id);
                    }}
                  >
                    <strong> ID: {item?.warehouse_id}</strong>
                    <br></br>
                    <strong> Name: {item?.name}</strong>
                    <br></br>
                    <strong> Balance: {item?.account_balance}</strong>
                    <br></br>
                  </Card>
                </Col>
              </>
            ))
          : ""}
      </Row>
    </>
  );

  return (
    <Content>
      {Pageheader}
      {CARD}
    </Content>
  );
};

export default connect(null, {
  getAllWarehouses,
})(WareHouse);
