import axios from "axios";
import history from "../history";
import {
  AUTH_ERROR,
  ERROR_MESSAGE,
  LOADED,
  LOADING,
  SUCCESS_MESSAGE,
  VALIDATION_AUTH_ERROR,
  VALIDATION_ERROR,
} from "../reducers/type";
import api from "./apis/index";

export const createRole = (data) => async (dispatch) => {
  dispatch({ type: LOADING });
  const access = {
    user_management: data.user_management ? data.user_management : false,
    driver_management: data.driver_management ? data.driver_management : false,
    warehouse_management: data.warehouse_management
      ? data.warehouse_management
      : false,
    order_management: data.order_management ? data.order_management : false,
    finance_management: data.finance_management
      ? data.finance_management
      : false,
    merchant_management: data.merchant_management
      ? data.merchant_management
      : false,
    message_management: data.message_management
      ? data.message_management
      : false,
    area_pricing_management: data.area_pricing_management
      ? data.area_pricing_management
      : false,
  };
  const obj = {
    name: data.role,
    active: true,
    access,
  };
  try {
    const response = await api(localStorage.getItem("token")).post(
      "/user-management/create-role",
      { ...obj }
    );

    if (response.status === 201) {
      dispatch({ type: LOADED });
      dispatch({ type: SUCCESS_MESSAGE, payload: response.data.message });
      history.push("/rolelist");
    }
  } catch (err) {
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if (err.message == "Network Error") {
      history.push("/networkError");
      return;
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });
    dispatch({ type: LOADED });
  }
};

export const getRoles = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).get(
      "/user-management/get-roles"
    );

    dispatch({ type: LOADED });
    return response.data.roles;
  } catch (err) {
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if (err.message == "Network Error") {
      history.push("/networkError");
      return;
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });
    dispatch({ type: LOADED });
  }
};

export const getRoleById = (id) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).get(
      `/user-management/role-detail?role=${id}`
    );

    dispatch({ type: LOADED });
    return response.data.role;
  } catch (err) {
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if (err.message == "Network Error") {
      history.push("/networkError");
      return;
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });
    dispatch({ type: LOADED });
  }
};

export const updateRole = (data, roleId) => async (dispatch) => {
  dispatch({ type: LOADING });
  const access = {
    user_management: data.user_management ? data.user_management : false,
    driver_management: data.driver_management ? data.driver_management : false,
    warehouse_management: data.warehouse_management
      ? data.warehouse_management
      : false,
    order_management: data.order_management ? data.order_management : false,
    finance_management: data.finance_management
      ? data.finance_management
      : false,
    merchant_management: data.merchant_management
      ? data.merchant_management
      : false,
    message_management: data.message_management
      ? data.message_management
      : false,
    area_pricing_management: data.area_pricing_management
      ? data.area_pricing_management
      : false,
  };
  const obj = {
    role: roleId,
    name: data.role,
    active: data.active,
    remark: data.remark,
    access,
  };
  try {
    const response = await api(localStorage.getItem("token")).patch(
      "/user-management/update-role",
      { ...obj }
    );

    if (response.status === 201) {
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: "Role is updated Successfully",
      });
      dispatch({ type: LOADED });
      history.push("/rolelist");
    } else {
    }
  } catch (err) {
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if (err.message == "Network Error") {
      history.push("/networkError");
      return;
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });
    dispatch({ type: LOADED });
  }
};

export const updateRoleAuth = (role, active) => async (dispatch) => {
  dispatch({ type: LOADING });

  const object = {
    role: role,
    active: active,
  };
  dispatch({ type: LOADED });

  try {
    const response = await api(localStorage.getItem("token")).patch(
      "/user-management/active-role",
      { ...object }
    );

    if (response.status === 201) {
      dispatch({ type: LOADED });
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: "Role authentication is updated succesfully",
      });

      return response.status;
    }
  } catch (err) {
    if (err?.response?.status === 422) {
      err.response.data.errors.map((item) => {
        dispatch({
          type: VALIDATION_ERROR,
          payload: [{ message: item.message, path: item.path[0] }],
        });
      });
    }
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
      localStorage.clear();
      history.push("/login");
    }
    if (err.message == "Network Error") {
      history.push("/networkError");
      return;
    }
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response
        ? err.response.data.message
        : history.push("/notFound"),
    });
    dispatch({ type: LOADED });
  }
};

export const deleteRole =
  (role, { remark }) =>
  async (dispatch) => {
    dispatch({ type: LOADING });

    try {
      const response = await axios(
        "https://api.amvines.hidayahsmart.solutions/archon/v1/user-management/delete-role",
        {
          headers: {
            "content-type": "application/json",
            authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          data: {
            role: role,
            remark: remark,
          },
          method: "delete",
        }
      );

      if (response.status === 201) {
        dispatch({ type: LOADED });
        dispatch({
          type: SUCCESS_MESSAGE,
          payload: "Role is deleted succesfully",
        });

        return response.status;
      }
    } catch (err) {
      if (err?.response?.status === 422) {
        err.response.data.errors.map((item) => {
          dispatch({
            type: VALIDATION_AUTH_ERROR,
            payload: [{ message: item?.message, path: item?.path[0] }],
          });
        });
      }
      if (err?.response?.status === 401 || err?.response?.status === 403) {
        dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
        localStorage.clear();
        history.push("/login");
      }
      if (err.message == "Network Error") {
        history.push("/networkError");
        return;
      }
      dispatch({
        type: ERROR_MESSAGE,
        payload: err.response
          ? err.response.data.message
          : history.push("/notFound"),
      });
      dispatch({ type: LOADED });
    }
  };
