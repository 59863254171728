import {
  Button,
  Card,
  Checkbox,
  Col,
  Descriptions,
  Divider,
  Form,
  Input,
  PageHeader,
  Row,
  Select,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import { getAreas } from "../../../actions/area";
import {
  createCustomOrder,
  getValidMerchantShop,
} from "../../../actions/parcel";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  renderInput,
  renderSelect,
  renderTextField,
} from "../../../components/form";
import InputBox from "../../../components/input";
import SelectBox from "../../../components/select";
const { Option } = Select;
const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 10, span: 10 },
};
const tp_names = [
  { value: "eCourier", label: "E-Courier" },
  { value: "redX", label: "RedX" },
  { value: "paperfly", label: "Paperfly" },
];

const gender = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
  { value: "other", label: "Other" },
];

const status = [
  { value: "ND", label: "Regular Delivery" },
  { value: "RD", label: "Same Day Delivery" },
  { value: "", label: "Select One" },
];
const product = [
  { value: "Parcel", label: "Parcel" },
  { value: "Document", label: "Document" },
];
const weights = [
  { value: ".5", label: "0-0.5" },
  { value: "1", label: "0.5-1" },
  { value: "2", label: "1-2" },
  { value: "3", label: "2-3" },
  { value: "4", label: "3-4" },
  { value: "5", label: "4-5" },
  { value: "6", label: "5-6" },
  { value: "7", label: "6-7" },
  { value: "8", label: "7-8" },
  { value: "9", label: "8-9" },
  { value: "10", label: "9-10" },
  { value: "11", label: "10-11" },
  { value: "12", label: "11-12" },
  { value: "13", label: "12-13" },
  { value: "14", label: "13-14" },
  { value: "15", label: "14-15" },
];

const CreateCustomParcel = ({
  location,
  createCustomOrder,
  getAreas,
  getValidMerchantShop,
}) => {
  const [parcelorder, setParcelOrder] = useState("");
  const [custom, setCustom] = useState(false);
  const [getmerchant, setGetMerchant] = useState(null);
  const [merchants, setMerchants] = useState("");
  const [mobilenumber, setMobileNumber] = useState("");
  const [data, setData] = useState(null);
  const [shop_id, setShopId] = useState(null);
  const [amount_to_collect, setammountOfCollect] = useState(null);
  const [areaCode, setAreaCode] = useState(null);
  const [delivery_type, setDeliveryType] = useState(null);
  const [merchant, setMerchant] = useState(null);
  const [number, setNumber] = useState(null);
  const [shopsdata, setShopsData] = useState(null);

  const [coverageArea, setCoverageArea] = useState(null);

  const [city, setCity] = useState("");
  const [subCity, setSubCity] = useState("");
  const [thana, setThana] = useState("");
  const [area, setArea] = useState("");
  const [cityArr, setCityArr] = useState(null);
  const [subCityArr, setsubCityArr] = useState(null);
  const [thanaArr, setThanaArr] = useState(null);
  const [areaArr, setAreaArr] = useState(null);
  const [amountCollect, setAmountCollect] = useState([]);
  const [delivercharge, setDeliveryCharge] = useState([]);
  const [codcharge, setCodCharge] = useState([]);

  const [form] = Form.useForm();

  useEffect(async () => {
    window.scrollTo(0, 0);
    const res = await getAreas();
    setCoverageArea(res);
  }, []);
  const AMOUNTCOLLECT = amountCollect;
  const tenpercent = amountCollect * (1 / 100);
  const CODCHARGE = Number((codcharge * tenpercent).toFixed(2));
  const handlePayable = () => {
    let res1 = Number(delivercharge) + CODCHARGE;
    let res2 = Number(AMOUNTCOLLECT, 10) - res1;
    return res2 ? res2 : "";
  };
  //   const TotalPaybale =
  //     AMOUNTCOLLECT !== null
  //       ? AMOUNTCOLLECT
  //       : delivercharge !== null
  //       ? AMOUNTCOLLECT - delivercharge
  //       : CODCHARGE !== null
  //       ? AMOUNTCOLLECT - CODCHARGE
  //       : delivercharge !== null && tenpercent !== null
  //       ? AMOUNTCOLLECT - delivercharge - CODCHARGE
  //       : null;

  const gridStyle = {
    width: "25%",
    textAlign: "center",
  };

  const city_ = async (event) => {
    const cityCode = event;
    if (cityCode === "-1") {
      return;
    }

    const subCity = [...coverageArea?.city[cityCode - 1]?.sub_city];
    setCity(cityCode);
    setsubCityArr(subCity);
    setSubCity("");
    setThana("");
    setArea("");
    setThanaArr(null);
    setAreaArr(null);
    form.setFieldsValue({
      subCity: "",
      thana: "",
      area: "",
    });
    // form.resetFields(
    //   setSubCity(""),
    //   setThana(""),
    //   setArea(""),
    //   setThanaArr(null),
    //   setAreaArr(null)
    // );
  };

  const sub_city = async (event) => {
    const subCode = event;
    if (subCode === "-1") {
      return;
    }
    const thana = [...subCityArr[subCode - 1].thana];

    setSubCity(subCode);
    setThana("");
    setArea("");
    setThanaArr(thana);
    form.setFieldsValue({
      thana: "",
      area: "",
    });
    // setAreaArr(null);
    // form.resetFields(setThana(""), setArea(""), setAreaArr(null));
    // form.setFieldsValue(setThana(""), setArea(""), setAreaArr(null));
  };

  const thana_ = async (event) => {
    const thanaCode = event;
    if (thanaCode === "-1") {
      return;
    }
    const area = [...thanaArr[thanaCode - 1].area];

    setThana(thanaCode);
    setAreaArr(area);
    setArea("");
    form.setFieldsValue({
      area: "",
    });
    // form.setFieldsValue( setArea(""));
    // form.resetFields( setArea(""));
  };

  const area_ = async (event) => {
    const areaCode = event;
    if (areaCode === "-1") {
      return;
    }
    setArea(areaCode);
    // form.resetFields();
  };

  // ========== Cities =========
  // console.log(coverageArea);
  const c_o =
    coverageArea != null
      ? coverageArea?.city?.map((item, id) =>
          item?.active ? (
            <Option key={id} value={item.id}>
              {item?.name}
            </Option>
          ) : null
        )
      : null;

  // ========== Sub cities ===========
  const s_o =
    subCityArr !== null
      ? subCityArr?.map((item, id) =>
          item?.active ? (
            <Option key={id} value={item?.id}>
              {item?.name}
            </Option>
          ) : null
        )
      : null;

  // ========== Thana ===========
  const t_o =
    thanaArr !== null
      ? thanaArr?.map((item, id) =>
          item?.active ? (
            <Option key={id} value={item?.id}>
              {item?.name}
            </Option>
          ) : null
        )
      : null;
  // ========== Area ===========
  const a_o =
    areaArr !== null
      ? areaArr?.map((item, id) =>
          item?.active ? (
            <Option key={id} value={item?.id}>
              {item?.name}
            </Option>
          ) : null
        )
      : null;

  const onFinish = async (values) => {
    const code = await createCustomOrder(
      values,
      custom,
      mobilenumber,
      city,
      subCity,
      thana,
      area
    );

    if (code === 200) {
      // form.resetFields();
    }
  };

  const handlemerchantSearch = async (e) => {
    window.scrollTo(0, 0);
    setNumber(e?.targe?.value);

    let value = e?.target?.value;

    if (value?.length === 11) {
      const res = await getValidMerchantShop(value);
      setShopsData(res?.shops);
      delete res?.shops;
      setMerchants(res);
      setData(value);
      setMobileNumber(value);
      setNumber(value);
    }
    if (value?.length > 11) {
      setData(value);
      // console.log('12 conditon',value)
    }
    if (value?.length < 11) {
      setData(value);
      // console.log('12 conditon',value)
    }
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  const getareasv = (label, value) => {
    return { label, value };
  };

  const createOption = (val, label) => {
    return { val, label };
  };

  let SHOPS = [];
  SHOPS = merchants
    ? merchants?.map((item) => getareasv(item.name, item.shop_id))
    : null;
  {
  }

  // let option = [];
  // option = area ? area.map((item) => getareasv(item.name, item.id)) : null;
  const SMS = (
    <>
      <Descriptions
        title="Search Mearchant"
        justify="center"
        labelStyle={{
          marginLeft: "150px",
        }}
      >
        <Descriptions.Item
          bordered={true}
          label="Merchant Mobile Number"
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
        >
          <Input
            variant="filled"
            name="merchant"
            label="Merchant Mobile Number"
            style={{ width: "50%" }}
            onChange={(e) => handlemerchantSearch(e)}
          />
        </Descriptions.Item>
      </Descriptions>
    </>
  );

  const columns = [
    {
      title: "",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Amount",
      dataIndex: "Amount",
      key: "Amount",
    },
  ];
  const FORM = (
    <Form
      {...layout}
      name="basic"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 14 }}
      form={form}
      autoComplete={false}
      requiredMark={false}
    >
      {SHOPS ? (
        <>
          <Divider orientation="center">Shop Information</Divider>

          <SelectBox
            name="shop_id"
            label="Shop Name"
            //   rules={[{ required: true, message: "This Field is required" }]}
            options={SHOPS}
            placeholder="Select One"
          />

          <Divider orientation="center">Order ID & Type </Divider>

          {renderSelect(
            "product_type",
            "Product Type",
            [{ required: true }],
            "Select a option",
            product,
            "none"
          )}
          {renderSelect(
            "delivery_type",
            "delivery Type",
            [{ required: true }],
            "Select a option",
            status,
            "none"
          )}
          {renderInput("merchant_order_id", "Order Reference No", [
            { required: true, message: "This field is required!" },
          ])}
          <Divider orientation="center">Area Details </Divider>

          {/* ================ City =============== */}

          <Form.Item
            name="city"
            label={"Select City"}
            rules={[
              {
                required: true,
                message: "City  is required",
              },
            ]}
          >
            <Select
              showSearch
              placeholder={"Select one city"}
              onChange={(e) => city_(e)}
            >
              {/* {options} */}
              {c_o}
            </Select>
          </Form.Item>
          {/* ================ Sub City ============ */}
          {subCityArr ? (
            <Form.Item
              name="subCity"
              label={"Select Subcity"}
              rules={[
                {
                  required: true,
                  message: "Subcity is required",
                },
              ]}
            >
              <Select
                showSearch
                placeholder={"Select one subcity"}
                onChange={(e) => sub_city(e)}
              >
                {/* {options} */}
                {s_o}
              </Select>
            </Form.Item>
          ) : null}

          {/* ============== Thana ============== */}
          {thanaArr ? (
            <Form.Item
              name="thana"
              label={"Select one thana"}
              rules={[
                {
                  required: true,
                  message: "Thana is required",
                },
              ]}
            >
              <Select
                showSearch
                placeholder={"Select one subcity"}
                onChange={(e) => thana_(e)}
              >
                {/* {options} */}
                {t_o}
              </Select>
            </Form.Item>
          ) : null}
          {/* ============== Area ============= */}
          {areaArr ? (
            <Form.Item
              name="area"
              label={"Select one area"}
              rules={[
                {
                  required: true,
                  message: "Area is required",
                },
              ]}
            >
              <Select
                showSearch
                placeholder={"Select one area"}
                onChange={(e) => area_(e)}
              >
                {/* {options} */}
                {a_o}
              </Select>
            </Form.Item>
          ) : null}

          <Divider orientation="center">Customer Details </Divider>

          {renderInput("recipient_name", "Recipient Name", [
            { required: true, message: "This field is required!" },
          ])}
          {renderInput("recipient_phone", "Recipient Phone", [
            { required: true, message: "This field is required!" },
            { pattern: /^(01)[0-9][0-9]{8}$/, message: "Please valid number!" },
          ])}
          {renderTextField("recipient_address", "Recipient Addrerss", [
            { required: true, message: "This field is required!" },
          ])}
          <Form.Item
            name="is_tp"
            valuePropName="checked"
            wrapperCol={{ offset: 8, span: 16 }}
            //defaultValue={false}
          >
            <Checkbox onChange={(e) => setCustom(e.target.checked)}>
              Select Third Party
            </Checkbox>
          </Form.Item>
          {custom ? (
            <>
              {renderSelect(
                "tp_name",
                "Third Party Name",
                [{ required: true }],
                "Select a option",
                tp_names,
                "none"
              )}
              {renderInput("tp_charge", "Third Party Charge", [
                { required: true, message: "This field is required!" },
              ])}
            </>
          ) : null}

          <Row gutter={16}>
            <Col xs={12} lg={12} sm={24} md={24}>
              <Card title="" bordered={true} hoverable>
                <>
                  <Divider>Product Details</Divider>
                  {renderSelect(
                    "weight",
                    "Weight (kg)",
                    [{ required: true }],
                    "Select a option",
                    weights,
                    "none"
                  )}

                  {renderInput("item_actual_price", "Actual Price", [
                    { required: true, message: "This field is required!" },
                    {},
                  ])}

                  <InputBox
                    name="amount_to_collect"
                    label="Collection Amount"
                    rules={[
                      { required: true, message: "This field is required!" },
                    ]}
                    onChange={(e) => {
                      setAmountCollect(e.target.value);
                    }}
                  />
                  <InputBox
                    name="delivery_charge"
                    label="Delivery charge"
                    rules={[
                      { required: true, message: "This field is required!" },
                    ]}
                    onChange={(e) => {
                      setDeliveryCharge(e.target.value);
                    }}
                  />
                  <InputBox
                    name="cod"
                    label="COD Charge"
                    rules={[
                      { required: true, message: "This field is required!" },
                    ]}
                    onChange={(e) => {
                      setCodCharge(e.target.value);
                    }}
                  />

                  {renderInput("return_charge", "Return Charge", [
                    { required: true, message: "This field is required!" },
                  ])}
                  {renderInput("quantity", "Quantity", [
                    { required: true, message: "This field is required!" },
                  ])}
                  {renderTextField("item_description", "Description", [
                    { required: true, message: "This field is required!" },
                  ])}
                  {renderTextField("note", "Note", [
                    { required: true, message: "This field is required!" },
                  ])}
                </>
              </Card>
            </Col>

            <Col xs={12} lg={12} sm={24} md={24}>
              <Card
                title=""
                bordered={true}
                hoverable
                style={{
                  position: "-webkit-sticky",
                  position: "sticky",
                  top: "0px",
                }}
              >
                <Divider style={{ backgroundColor: "#820374", color: "white" }}>
                  Cost Calculation
                </Divider>

                <div>
                  Amount to collect : <strong>{AMOUNTCOLLECT} ৳</strong>
                </div>
                <br></br>
                <div>
                  Total delivery charge : <strong>{delivercharge} ৳</strong>
                </div>
                <br></br>
                <div>
                  COD charge :<strong> {CODCHARGE} ৳</strong>
                </div>
                <br></br>
                <div>
                  Total payable amount : <strong>{handlePayable()} ৳</strong>
                </div>
                <br></br>
                <div
                  style={{
                    display: "block",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  <strong>{handlePayable()} ৳</strong>
                </div>
              </Card>
            </Col>
          </Row>
          <br></br>

          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </>
      ) : null}
    </Form>
  );
  const Pageheader = (
    <PageHeader
      ghost={false}
      onBack={() => window.history.back()}
      title="Custom Parcel Create"
      subTitle="Parcel Management"
      extra={[]}
    ></PageHeader>
  );
  return (
    <Content>
      {Pageheader}
      <br></br>
      {SMS}
      {FORM}
    </Content>
  );
};

export default connect(null, {
  createCustomOrder,
  getAreas,
  getValidMerchantShop,
})(CreateCustomParcel);
