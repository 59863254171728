import {
  Button,
  Card,
  DatePicker,
  Divider,
  Form,
  Modal,
  PageHeader,
  Select,
} from "antd";
import React, { useEffect } from "react";

import { useState } from "react";
import { connect } from "react-redux";
import {
  collectAmountFromWarehouse,
  getAlWarehouseDetails,
} from "../../../actions/account";

import { Content } from "antd/lib/layout/layout";

import { renderInput, renderSelect } from "../../../components/form";
import SelectBox from "../../../components/select";
const { Option } = Select;
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 12 },
};
const tailLayout = {
  wrapperCol: { offset: 6, span: 14 },
};

const WarehouseDetails = ({
  location,
  getAlWarehouseDetails,
  collectAmountFromWarehouse,
}) => {
  const [data, setData] = useState("");

  const [warehouse, setWarehouse] = useState("");
  const [ID, setId] = useState([]);
  const [passVisible, setPassVisible] = useState(false);
  const [visibleedit, setVisibleEdit] = useState(false);
  const [Paymoney, setPayMoney] = useState(false);
  const [pt, setPaymentType] = useState("");
  const [bpo, setBPO] = useState("");
  const [docimg, setDocImg] = useState(false);
  const [form] = Form.useForm();
  const [forms] = Form.useForm();
  const banks = [
    { value: "AB Bank LTD", label: "AB Bank LTD" },
    { value: "Agrani Bank LTD", label: "Agrani Bank LTD" },
    { value: "Al-Arafah Islami Bank LTD", label: "Al-Arafah Islami Bank LTD" },
    { value: "BASIC Bank LTD", label: "BASIC Bank LTD" },
    {
      value: "BDBL (Bangladesh Development Bank LTD)",
      label: "BDBL (Bangladesh Development Bank LTD)",
    },
    { value: "BRAC Bank LTD", label: "BRAC Bank LTD" },
    {
      value: "Bangladesh Commerce Bank LTD",
      label: "Bangladesh Commerce Bank LTD",
    },
    { value: "Bank Al-Falah", label: "Bank Al-Falah" },
    { value: "Bank Asia LTD", label: "Bank Asia LTD" },
    { value: "Citibank NA", label: "Citibank NA" },
    { value: "Commercial Bank of Ceylon", label: "Commercial Bank of Ceylon" },
    { value: "Dhaka Bank LTD", label: "Dhaka Bank LTD" },
    { value: "Dutch Bangla Bank LTD", label: "Dutch Bangla Bank LTD" },
    { value: "Eastern Bank LTD", label: "Eastern Bank LTD" },
    {
      value: "Export Import Bank of Bangladesh LTD",
      label: "Export Import Bank of Bangladesh LTD",
    },
    {
      value: "First Security Islami Bank LTD",
      label: "First Security Islami Bank LTD",
    },
    { value: "HSBC", label: "HSBC" },
    { value: "Habib Bank LTD", label: "Habib Bank LTD" },
    { value: "ICB Islamic Bank LTD", label: "ICB Islamic Bank LTD" },
    { value: "IFIC Bank LTD", label: "IFIC Bank LTD" },
    {
      value: "Islami Bank Bangladesh LTD",
      label: "Islami Bank Bangladesh LTD",
    },
    { value: "Jamuna Bank LTD", label: "Jamuna Bank LTD" },
    { value: "Janata Bank LTD", label: "Janata Bank LTD" },
    { value: "Meghna Bank LTD", label: "Meghna Bank LTD" },
    { value: "Mercantile Bank LTD", label: "Mercantile Bank LTD" },
    { value: "Midland Bank LTD", label: "Midland Bank LTD" },
    { value: "Modhumoti Bank LTD", label: "Modhumoti Bank LTD" },
    { value: "Mutual Trust Bank LTD", label: "Mutual Trust Bank LTD" },
    { value: "NCC Bank LTD", label: "NCC Bank LTD" },
    { value: "NRB Bank LTD", label: "NRB Bank LTD" },
    { value: "NRB Commercial Bank LTD", label: "NRB Commercial Bank LTD" },
    { value: "NRB Global Bank LTD", label: "NRB Global Bank LTD" },
    { value: "National Bank LTD", label: "National Bank LTD" },
    { value: "National Bank of Pakistan", label: "National Bank of Pakistan" },
    { value: "One Bank LTD", label: "One Bank LTD" },
    { value: "Prime Bank LTD", label: "Prime Bank LTD" },
    { value: "Pubali Bank LTD", label: "Pubali Bank LTD" },
    { value: "Rupali Bank LTD", label: "Rupali Bank LTD" },
    { value: "Shahjalal islami Bank LTD", label: "Shahjalal islami Bank LTD" },
    { value: "Simanto Bank LTD", label: "Simanto Bank LTD" },
    { value: "Social Islami Bank LTD", label: "Social Islami Bank LTD" },
    { value: "Sonali Bank LTD", label: "Sonali Bank LTD" },
    {
      value: "South Bangla Agriculture and Commerce Bank LTD",
      label: "South Bangla Agriculture and Commerce Bank LTD",
    },
    { value: "Southeast Bank LTD", label: "Southeast Bank LTD" },
    { value: "Standard Bank LTD", label: "Standard Bank LTD" },
    { value: "Standard Chartered Bank", label: "Standard Chartered Bank" },
    { value: "State Bank of India", label: "State Bank of India" },
    { value: "The City Bank LTD", label: "The City Bank LTD" },
    { value: "The Farmers Bank LTD", label: "The Farmers Bank LTD" },
    { value: "The Premier Bank LTD", label: "The Premier Bank LTD" },
    { value: "Trust Bank LTD", label: "Trust Bank LTD" },
    { value: "Union Bank LTD", label: "Union Bank LTD" },
    {
      value: "United Commercial Bank LTD",
      label: "United Commercial Bank LTD",
    },
    { value: "Uttara Bank LTD", label: "Uttara Bank LTD" },
    { value: "Woori Bank", label: "Woori Bank" },
  ];
  const payment_method = [
    { value: "Mobile Banking", label: "Mobile Banking" },
    { value: "Bank Account", label: "Bank Account" },
    { value: "Cash", label: "Cash" },
  ];
  const mobile_type = [
    { value: "bKash", label: "bKash" },
    { value: "Rocket", label: "Rocket" },
    { value: "Nagad", label: "Nagad" },
  ];
  const bank_payment_option = [
    { value: "Check Voucher", label: "Check Voucher" },
    { value: "Voucher", label: "Voucher" },
  ];
  const warehouse_id = new URLSearchParams(location.search).get("warehouse_id");
  async function fetchData() {
    const res = await getAlWarehouseDetails(warehouse_id);

    setData(res);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
  }, []);

  const onFinish = async (values) => {
    const code = await collectAmountFromWarehouse(
      values,
      data?.warehouse_id,
      docimg
    );
    if (code === 201) {
      form.resetFields();
    }
  };
  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };
  const createPlace = (label, value) => {
    return { label, value };
  };

  let option = [];
  option = data
    ? data.managers.map((item) => createPlace(item.name, item.user_id))
    : null;

  const handleCancelEdit = () => {
    setVisibleEdit(false);
  };

  const FROM = (
    <Modal
      title={data?.name}
      centered
      visible={Paymoney}
      width={600}
      onCancel={() => setPayMoney(false)}
      cancelText="Close"
      okButtonProps={{ style: { display: "none" } }}
    >
      <Form
        {...layout}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        form={form}
      >
        <Divider style={{ color: "#820374" }}>{data?.name}</Divider>
        {/* {renderDate("date", "Collection Date", [
            { required: true, message: "Field is required" },
          ])} */}
        {/* {renderSelect(
              "manager_id",
              "Select Warehouse Manager",
              [{ required: true }],
              "Select One",
              {option},
              "none"
            )} */}
        <SelectBox
          name="manager_id"
          label="Warehouse Manager"
          rules={[{ required: true, message: "Field type is required" }]}
          options={option}
          placeholder="Select One"
          onChange={(e) => {
            setPaymentType(e);
          }}
        />
        <Form.Item
          label="Collection Date"
          name="date"
          rules={[{ required: true, message: "Field type is required" }]}
        >
          <DatePicker style={{ width: "100%" }} size="large" name="date" />
        </Form.Item>
        {renderInput("amount", "Amount to Collect", [
          { required: true, message: "field is required" },
        ])}
        {renderInput("note", "Note", [
          { required: true, message: "Field is required" },
        ])}
        <SelectBox
          name="payment_method"
          label="Payment Method"
          rules={[{ required: true, message: "payment type is required" }]}
          options={payment_method}
          placeholder="Select One"
          onChange={(e) => {
            setPaymentType(e);
          }}
        />
        {pt === "Mobile Banking" ? (
          <>
            {renderSelect(
              "mobile_bank",
              "Mobile Banking Name*",
              [{ required: true }],
              "Select One",
              mobile_type,
              "none"
            )}
            {renderInput("mobile_banking_account", "Mobile Number ", [
              { required: true, message: "Field is required" },
            ])}
            {renderInput("tx_id", "Transaction ID", [
              { required: true, message: "Field is required" },
            ])}

            <br></br>
          </>
        ) : null}
        {pt === "Bank Account" ? (
          <>
            <SelectBox
              name="bank_payment_option"
              label="Bank payment option"
              rules={[{ required: true, message: "Field is required" }]}
              options={bank_payment_option}
              placeholder="Select One"
              onChange={(e) => {
                setBPO(e);
              }}
            />

            {bpo === "Check Voucher" ? (
              <>
                {renderSelect(
                  "bank_name",
                  "Bank Name*",
                  [{ required: true }],
                  "Select One",
                  banks,
                  "none"
                )}

                {renderInput("branch_name", "Brance Name", [
                  { required: true, message: "Field is required" },
                ])}
                {renderInput("check_holder", "Check holder", [
                  { required: true, message: "field is required" },
                ])}
                {renderInput("issue_name", "Issuer Name ", [
                  { required: true, message: "field is required" },
                ])}
                {renderInput("check_number", "Check Number", [
                  { required: true, message: "field is required" },
                ])}
              </>
            ) : null}
            {bpo === "Voucher" ? (
              <>
                {renderSelect(
                  "bank_name",
                  "Bank Name*",
                  [{ required: true }],
                  "Select One",
                  banks,
                  "none"
                )}

                {renderInput("branch_name", "Brance Name", [
                  { required: true, message: "Field is required" },
                ])}
              </>
            ) : null}
          </>
        ) : null}
        <Form.Item label="Image of Documnet">
          <input
            type="file"
            id="avatar"
            name="doc_img"
            accept="image/png, image/jpeg"
            onChange={(e) => {
              setDocImg(e.target.files[0]);
            }}
          ></input>
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );

  const handleOk = () => {
    setPassVisible(false);
  };

  const handleCancel = () => {
    setPassVisible(false);
  };
  const gridStyle = {
    width: "25%",
    textAlign: "center",
    backgroundColor: "#820374",
    color: "white",
  };

  const pageheader = (
    <>
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Warehouse Details"
        subTitle="Finance Management"
        extra={[
          <>
            <Button
              size="large"
              type="primary"
              onClick={() => setPayMoney(true)}
            >
              Collect&nbsp; <strong>{data?.account_balance} Tk</strong>
            </Button>
          </>,
        ]}
      ></PageHeader>
      {FROM}
      <br></br>
    </>
  );
  const warehousedetails = (
    <>
      <Card hoverable>
        <Divider>WareHouse Details</Divider>
        <h1 style={{ color: "#820374", display: "inline-block" }}>
          Id: {data?.warehouse_id}
        </h1>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <h1 style={{ color: "#820374", display: "inline-block" }}>
          Name: {data?.name}
        </h1>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <h1 style={{ color: "#820374", display: "inline-block" }}>
          Phone: {data?.mobile_number}
        </h1>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <h1 style={{ color: "#820374", display: "inline-block" }}>
          Address: {data?.address}
        </h1>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <h1 style={{ color: "#820374", display: "inline-block" }}>
          Account Balance: {data?.account_balance} TK
        </h1>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </Card>
      <br></br>
      <p></p>

      <Card hoverable>
        <Divider style={{ color: "#820374" }}>WareHouse Manager</Divider>
        {data
          ? data?.managers.map((item, id) => (
              <>
                <Card.Grid style={gridStyle}>
                  ID : {item?.user_id}
                  <br></br>
                  Name : {item?.name}
                  <br></br>
                  Mobile Number : {item?.mobile_number}
                  <br></br>
                </Card.Grid>
              </>
            ))
          : ""}
      </Card>
      <br></br>
    </>
  );
  return (
    <Content>
      {pageheader}
      <br></br>
      {warehousedetails}
    </Content>
  );
};

export default connect(null, {
  getAlWarehouseDetails,
  collectAmountFromWarehouse,
})(WarehouseDetails);
