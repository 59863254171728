import {
  Card,
  Col,
  Descriptions,
  Empty,
  Form,
  Modal,
  PageHeader,
  Row,
  Timeline,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { getTransactionDetails } from "../../../actions/account";

const tailLayout = {
  wrapperCol: { offset: 4, span: 16 },
};
const gender = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
  { value: "other", label: "Other" },
];
const status = [
  { value: true, label: "Active" },
  { value: false, label: "Inactive" },
];
const Wrapper = styled.div`
  padding: 20px;
`;
const Title = styled.div`
  width: 100%;
  background-color: #820374;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  padding: 5px;
  text-align: center;
`;
const TransactionDetails = ({ location, getTransactionDetails, tID }) => {
  const [Id, setId] = useState("");
  const [data, setData] = useState([]);
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(async () => {
    window.scrollTo(0, 0);
    if (tID) {
      const res = await getTransactionDetails(tID);
      setData(res);
    }
    if (location) {
      const id = new URLSearchParams(location?.search).get("transaction_id");
      const res = await getTransactionDetails(id);
      setData(res);
    }
  }, [tID]);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const pageheader = (
    <>
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Transaction Details"
        subTitle="Finance  Management"
      ></PageHeader>
      <br></br>
    </>
  );
  const picmodal = (
    <>
      <Modal
        title="Image"
        visible={isModalVisible}
        cancelText="Close"
        okButtonProps={{ style: { display: "none" } }}
        onCancel={handleCancel}
      >
        <img
          onClick={showModal}
          src={`https://api.amvines.hidayahsmart.solutions/document/${data?.transaction?.transaction_detail?.doc_img}`}
          style={{ width: "300px" }}
          alt="Transaction doc image"
        />
      </Modal>
    </>
  );

  const detailsuser = (
    <>
      <Wrapper>
        {data?.transaction ? (
          <>
            <Row gutter={16}>
              <Col xs={12} lg={12} sm={24} md={24}>
                <Card hoverable style={{ maxHeight: 300, minHeight: 300 }}>
                  <>
                    <Title>User Information </Title>
                    <br />
                    <Descriptions>
                      <Descriptions.Item label="ID">
                        <strong>{data?.transaction?.admin?.user_id}</strong>
                      </Descriptions.Item>
                    </Descriptions>
                    <Descriptions>
                      <Descriptions.Item label="Name">
                        <strong>{data?.transaction?.admin?.name}</strong>
                      </Descriptions.Item>
                    </Descriptions>
                    <Descriptions>
                      <Descriptions.Item label="Mobile Number">
                        <strong>
                          {data?.transaction?.admin?.mobile_number}
                        </strong>
                      </Descriptions.Item>
                    </Descriptions>
                    <Descriptions>
                      <Descriptions.Item label="Note" span={2}>
                        <strong>{data?.transaction?.ops?.message}</strong>
                      </Descriptions.Item>
                    </Descriptions>
                  </>
                </Card>
                <br></br>
              </Col>
              <Col xs={12} lg={12} sm={24} md={24}>
                <Card hoverable style={{ maxHeight: 300, minHeight: 300 }}>
                  {data?.transaction ? (
                    <>
                      <Title>Transaction Information </Title>
                      <br />
                      <Descriptions>
                        <Descriptions.Item label="Transaction ID" span={1.5}>
                          <strong>{data?.transaction?.transaction_id}</strong>
                        </Descriptions.Item>
                      </Descriptions>
                      <Descriptions>
                        <Descriptions.Item label="Transaction State">
                          <strong>
                            {data?.transaction?.transaction_state}
                          </strong>
                        </Descriptions.Item>
                      </Descriptions>
                      <Descriptions>
                        <Descriptions.Item label="Transfer Amount">
                          <strong>
                            {data?.transaction?.transfer_amount} TK
                          </strong>
                        </Descriptions.Item>
                      </Descriptions>
                      <Descriptions>
                        <Descriptions.Item label="Payment Method">
                          <strong>
                            {
                              data?.transaction?.transaction_detail
                                ?.payment_method
                            }{" "}
                          </strong>
                        </Descriptions.Item>
                      </Descriptions>
                      <Descriptions>
                        <Descriptions.Item label="Create At">
                          <strong>
                            {moment
                              .utc(data?.transaction?.created_at)
                              .format("YYYY-MM-DD hh:mm A")}
                          </strong>
                        </Descriptions.Item>
                      </Descriptions>
                      <Descriptions>
                        <Descriptions.Item label="Note">
                          <strong>
                            {data?.transaction?.transaction_detail?.note}{" "}
                          </strong>
                        </Descriptions.Item>
                      </Descriptions>
                    </>
                  ) : null}
                </Card>
                <br></br>
              </Col>
            </Row>
            <Row gutter={16}>
              {data?.transaction?.transaction_detail?.sender ? (
                <>
                  <Col xs={12} lg={12} sm={24} md={24}>
                    <Card hoverable style={{ maxHeight: 300, minHeight: 300 }}>
                      <Title>Sender Information </Title>

                      <br />

                      {data?.transaction?.transaction_detail?.sender?.state ? (
                        <Descriptions>
                          <Descriptions.Item label="Sender">
                            <strong>
                              {
                                data?.transaction?.transaction_detail?.sender
                                  ?.state
                              }{" "}
                            </strong>
                          </Descriptions.Item>
                        </Descriptions>
                      ) : null}

                      {data?.transaction?.transaction_detail?.sender
                        ?.bank_name ? (
                        <Descriptions>
                          <Descriptions.Item label="Bank Name">
                            <strong>
                              {
                                data?.transaction?.transaction_detail?.sender
                                  ?.bank_name
                              }
                            </strong>
                          </Descriptions.Item>
                        </Descriptions>
                      ) : null}
                      {data?.transaction?.transaction_detail?.sender
                        ?.bank_payment_option ? (
                        <Descriptions>
                          <Descriptions.Item label="Banking Payment Option">
                            {" "}
                            <strong>
                              {
                                data?.transaction?.transaction_detail?.sender
                                  ?.bank_payment_option
                              }
                            </strong>
                          </Descriptions.Item>
                        </Descriptions>
                      ) : null}
                      {data?.transaction?.transaction_detail?.sender
                        ?.branch_name ? (
                        <Descriptions>
                          <Descriptions.Item label="Branch name">
                            {" "}
                            <strong>
                              {
                                data?.transaction?.transaction_detail?.sender
                                  ?.branch_name
                              }
                            </strong>
                          </Descriptions.Item>
                        </Descriptions>
                      ) : null}
                      {data?.transaction?.transaction_detail?.sender
                        ?.account ? (
                        <Descriptions>
                          <Descriptions.Item label="Payment Type">
                            {" "}
                            <strong>
                              {
                                data?.transaction?.transaction_detail?.sender
                                  ?.account
                              }
                            </strong>
                          </Descriptions.Item>
                        </Descriptions>
                      ) : null}
                      {data?.transaction?.transaction_detail?.sender
                        ?.mobile_bank ? (
                        <Descriptions>
                          <Descriptions.Item label="Mobile Financial Service">
                            {" "}
                            <strong>
                              {
                                data?.transaction?.transaction_detail?.sender
                                  ?.mobile_bank
                              }
                            </strong>
                          </Descriptions.Item>
                        </Descriptions>
                      ) : null}
                      {data?.transaction?.transaction_detail?.sender
                        ?.mobile_banking_account ? (
                        <Descriptions>
                          <Descriptions.Item label="Account">
                            {" "}
                            <strong>
                              {
                                data?.transaction?.transaction_detail?.sender
                                  ?.mobile_banking_account
                              }
                            </strong>
                          </Descriptions.Item>
                        </Descriptions>
                      ) : null}
                      {data?.transaction?.transaction_detail?.sender?.tx_id ? (
                        <Descriptions>
                          <Descriptions.Item label="Trx ID">
                            {" "}
                            <strong>
                              {
                                data?.transaction?.transaction_detail.sender
                                  .tx_id
                              }
                            </strong>
                          </Descriptions.Item>
                        </Descriptions>
                      ) : null}
                      {data?.transaction?.transaction_detail?.doc_img ? (
                        <Descriptions>
                          <Descriptions.Item label="Image">
                            {" "}
                            <img
                              onClick={showModal}
                              src={`https://api.amvines.hidayahsmart.solutions/document/${data?.transaction?.transaction_detail?.doc_img}`}
                              style={{ width: "90px" }}
                              alt="Transaction doc image"
                            />
                            {/* </Button> */}
                            {picmodal}
                          </Descriptions.Item>
                        </Descriptions>
                      ) : null}

                      <br />
                    </Card>

                    <br></br>
                  </Col>
                </>
              ) : null}
              {data?.transaction?.transaction_detail?.receiver ? (
                <>
                  <Col xs={12} lg={12} sm={24} md={24}>
                    <Card hoverable style={{ maxHeight: 300, minHeight: 300 }}>
                      <Title>Reciver Information </Title>
                      <br />

                      {data?.transaction?.transaction_detail?.receiver
                        ?.state ? (
                        <Descriptions>
                          <Descriptions.Item label="Reciver">
                            <strong>
                              {
                                data?.transaction?.transaction_detail?.receiver
                                  ?.state
                              }{" "}
                            </strong>
                          </Descriptions.Item>
                        </Descriptions>
                      ) : null}
                      {data?.transaction?.transaction_detail?.receiver
                        ?.bank_name ? (
                        <Descriptions>
                          <Descriptions.Item label="Bank Name">
                            {" "}
                            <strong>
                              {
                                data?.transaction?.transaction_detail?.receiver
                                  ?.bank_name
                              }
                            </strong>
                          </Descriptions.Item>
                        </Descriptions>
                      ) : null}
                      {data?.transaction?.transaction_detail?.receiver
                        ?.bank_payment_option ? (
                        <Descriptions>
                          <Descriptions.Item label="Banking Payment Option">
                            {" "}
                            <strong>
                              {
                                data?.transaction?.transaction_detail?.receiver
                                  ?.bank_payment_option
                              }
                            </strong>
                          </Descriptions.Item>
                        </Descriptions>
                      ) : null}
                      {data?.transaction?.transaction_detail?.receiver
                        ?.branch_name ? (
                        <Descriptions>
                          <Descriptions.Item label="Branch name">
                            {" "}
                            <strong>
                              {
                                data?.transaction?.transaction_detail?.receiver
                                  ?.branch_name
                              }
                            </strong>
                          </Descriptions.Item>
                        </Descriptions>
                      ) : null}
                      {data?.transaction?.transaction_detail?.receiver
                        ?.account ? (
                        <Descriptions>
                          <Descriptions.Item label="Payment Type">
                            {" "}
                            <strong>
                              {
                                data?.transaction?.transaction_detail?.receiver
                                  ?.account
                              }
                            </strong>
                          </Descriptions.Item>
                        </Descriptions>
                      ) : null}
                      {data?.transaction?.transaction_detail?.receiver
                        ?.mobile_bank ? (
                        <Descriptions>
                          <Descriptions.Item label="Mobile Financial Service">
                            {" "}
                            <strong>
                              {
                                data?.transaction?.transaction_detail?.receiver
                                  ?.mobile_bank
                              }
                            </strong>
                          </Descriptions.Item>
                        </Descriptions>
                      ) : null}
                      {data?.transaction?.transaction_detail?.receiver
                        ?.mobile_banking_account ? (
                        <Descriptions>
                          <Descriptions.Item label="Account">
                            {" "}
                            <strong>
                              {
                                data?.transaction?.transaction_detail?.receiver
                                  ?.mobile_banking_account
                              }
                            </strong>
                          </Descriptions.Item>
                        </Descriptions>
                      ) : null}
                      {data?.transaction?.transaction_detail?.receiver
                        ?.tx_id ? (
                        <Descriptions>
                          <Descriptions.Item label="Trx ID">
                            {" "}
                            <strong>
                              {
                                data?.transaction?.transaction_detail.receiver
                                  .tx_id
                              }
                            </strong>
                          </Descriptions.Item>
                        </Descriptions>
                      ) : null}

                      <br />
                    </Card>
                    <br></br>
                  </Col>
                </>
              ) : null}
            </Row>
            <Row gutter={16}>
              {data?.transaction?.merchant ? (
                <>
                  <Col xs={12} lg={12} sm={24} md={24}>
                    <Card hoverable style={{ maxHeight: 300, minHeight: 300 }}>
                      <Title>Merchant Information </Title>
                      <br />
                      <Descriptions>
                        {data?.transaction?.merchant?.name ? (
                          <Descriptions.Item label="Name">
                            <strong>{data?.transaction?.merchant?.name}</strong>
                          </Descriptions.Item>
                        ) : null}
                      </Descriptions>
                      <Descriptions>
                        {data?.transaction?.merchant?.mobile_number ? (
                          <Descriptions.Item label="Mobile Number">
                            {" "}
                            <strong>
                              {data?.transaction?.merchant?.mobile_number}
                            </strong>
                          </Descriptions.Item>
                        ) : null}
                      </Descriptions>
                      <Descriptions>
                        {data?.transaction?.merchant?.address ? (
                          <Descriptions.Item label="Address" span={3}>
                            {" "}
                            <strong>
                              {data?.transaction?.merchant?.address}
                            </strong>
                          </Descriptions.Item>
                        ) : null}

                        <br />
                      </Descriptions>
                    </Card>
                    <br></br>
                  </Col>
                </>
              ) : null}
              {data?.transaction?.shop ? (
                <>
                  <Col xs={12} lg={12} sm={24} md={24}>
                    <Card hoverable style={{ maxHeight: 300, minHeight: 300 }}>
                      <Title>Shop Information </Title>
                      <br />
                      <Descriptions>
                        {data?.transaction?.shop?.name ? (
                          <Descriptions.Item label="Name" span={1.5}>
                            <strong>{data?.transaction?.shop?.name}</strong>
                          </Descriptions.Item>
                        ) : null}
                        {data?.transaction?.shop?.mobile_number ? (
                          <Descriptions.Item label="Mobile Number" span={1.5}>
                            {" "}
                            <strong>
                              {data?.transaction?.shop?.mobile_number}
                            </strong>
                          </Descriptions.Item>
                        ) : null}
                      </Descriptions>
                      <Descriptions>
                        {data?.transaction?.shop?.address ? (
                          <Descriptions.Item label="Address" span={3}>
                            {" "}
                            <strong>{data?.transaction?.shop?.address}</strong>
                          </Descriptions.Item>
                        ) : null}
                      </Descriptions>
                      <Descriptions>
                        {data?.transaction?.shop?.pickup_address ? (
                          <Descriptions.Item label="Pickup Address" span={3}>
                            {" "}
                            <strong>
                              {data?.transaction?.shop?.pickup_address}
                            </strong>
                          </Descriptions.Item>
                        ) : null}

                        <br />
                      </Descriptions>
                    </Card>
                    <br></br>
                  </Col>
                </>
              ) : null}
            </Row>

            {data?.transaction?.driver ? (
              <>
                <Title>Driver Information </Title>
                <br />
                <Descriptions>
                  {data?.transaction?.driver?.user_id ? (
                    <Descriptions.Item label="ID">
                      {" "}
                      <strong>{data?.transaction?.driver?.user_id}</strong>
                    </Descriptions.Item>
                  ) : null}

                  {data?.transaction?.driver?.name ? (
                    <Descriptions.Item label="Name">
                      <strong>{data?.transaction?.driver?.name}</strong>
                    </Descriptions.Item>
                  ) : null}
                  {data?.transaction?.driver?.mobile_number ? (
                    <Descriptions.Item label="Mobile Number">
                      {" "}
                      <strong>
                        {data?.transaction?.driver?.mobile_number}
                      </strong>
                    </Descriptions.Item>
                  ) : null}
                </Descriptions>
                <Descriptions>
                  {data?.transaction?.driver?.address ? (
                    <Descriptions.Item label="Address" span={3}>
                      {" "}
                      <strong>{data?.transaction?.driver?.address}</strong>
                    </Descriptions.Item>
                  ) : null}

                  <br />
                </Descriptions>
              </>
            ) : null}
          </>
        ) : null}
        <br></br>

        {data?.transaction?.logs ? (
          <>
            <Title>Logs </Title>
            <br />
            {data?.transaction?.logs ? (
              <Timeline mode="left">
                {data?.transaction?.logs.map((item, index) => (
                  <Timeline.Item
                    label={moment
                      .utc(item?.created_at)
                      .format("YYYY-MM-DD hh:mm A")}
                    keys={index}
                  >
                    {item?.message}
                  </Timeline.Item>
                ))}
              </Timeline>
            ) : (
              <Empty />
            )}
          </>
        ) : null}
        <br />
      </Wrapper>
    </>
  );

  return (
    <Content>
      {pageheader}
      <br></br>
      {detailsuser}
    </Content>
  );
};

export default connect(null, {
  getTransactionDetails,
})(TransactionDetails);
